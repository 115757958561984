import React, { useState, useEffect, useContext } from 'react';
import './CardGreen.css';
import { ClaimContext } from '../../context/ClaimContext';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import optimaSecureImg from '../../Assets/optima_secure.svg';
import {indianCurrencyFormat} from "../../utility"

const CardGreen = () => {
  const context = useContext(ClaimContext);
  // console.log("hihihihiih",context.data);

  if (context.data) {
    return (
      <>
        <section className="cardgreen-main">
          {/* <div className='card-main'>
            <div className='card-content'>
              <div className='box1'>
                <span className='semi-bold'>
                  <p className='card-amount'>
                    &#8377;{context.data.total_claimamount}/-
                  </p>
                </span>
                <p>Claim Amount Filed</p>
              </div>
              <div className='box2'>
                <span className='semi-bold'>
                  <p className='card-amount'>
                    &#8377; {context.data.approved_amt}/-
                  </p>
                </span>
                <p>Approved Claim Amount</p>
              </div>
            </div>
            <div className='claim-status'>
              <p className='claim-status-flex'>
                Claim Id
                <span className='semi-bold'> {context.data.claim_id}</span>
              </p>
              <p className='claim-status-flex'>
                <span className='green-dot' /> Status
                <span className='semi-bold'>
                  {context.data.claim_status === 'Approved'
                    ? 'Claim Approved'
                    : 'Amount Disbursed'}
                </span> 
              </p>
              <p className='claim-status-flex'>
                Transaction ID
                <span className='semi-bold'>{context.data.transaction_id}</span>
              </p>
            </div>
          </div> */}
          <div className="optima_secure_div_green">
            <div className="optima_secure_imgtext">
              <div className="optima_secure_img">
                <img src={optimaSecureImg} alt="" />
              </div>
              <div className="optima_secure_txt">
                <p>{context.data.product_name.substr(0, 50)}</p>
                <p>{context.data.policy_number}</p>
              </div>
            </div>
            <div className="optima_secure_txt_f">
              <p>Claim Id</p>
              <p>Status</p>
            </div>
            <div className="optima_secure_txt_s">
              <p>{context.data.claim_id}</p>
              <p>
                <span className="doc_ver_dot"></span>{' '}
                {context.data.claim_status}
              </p>
            </div>
            <hr />
            <div className="optima_secure_txt_l">
              <p>
                {context.data.claim_status}
                {/* {context.data.claim_status === 'Approved'
                  ? 'Amount Approved'
                  : 'Amount Approved & Disbursed'} */}
              </p>
              <p>₹ {indianCurrencyFormat(context.data.approved_amt||context.data.total_claimamount)}/-</p>
            </div>
          </div>
        </section>
      </>
    );
  } else {
    return (
      <section className="card-main-skeleton">
        <Skeleton height="8.5rem" circle={false} count={1} />
      </section>
    );
  }
};

export default CardGreen;
