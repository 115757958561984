import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './ClaimStatus.css';
import goodByImg from '../../Assets/goodbye.png';
import EmptyStateImg from '../../Assets/EmptyState.svg';
import hdfc from '../../Assets/hdfclogo.png';
import ClaimStatusCard from './ClaimStatusCard/ClaimStatusCard';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import logoutImg from '../../Assets/logout.svg';
import Header from '../Header/Header';


// import { Stats } from './claimStatusStats/ClaimsStats';
const ClaimStatus = ({ available_claims_data, set_and_shift_to_status }) => {
  // console.log('available_claims_data', available_claims_data);

  const claim_stats = { total: 0 };
  const navigate = useNavigate();
  const { id, usersPolicyNumber } = useParams();

  const [clicked_section, setClickedSection] = useState(id);
  const [cashlessPopUpStat, setCashlessPopUpStat] = useState(false);
  const [deviceSize, changeDeviceSize] = useState(window.screen.width);


  // ========modelstyle=====
  const style = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '490px',
    maxWidth: '90%',
    bgcolor: 'background.paper',
    // border: '1px solid #000',
    borderRadius: '4px',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  for (let claim_type in available_claims_data) {
    claim_stats['total'] =
      claim_stats['total'] + available_claims_data[claim_type].length;
    if (!claim_stats[claim_type]) {
      claim_stats[claim_type] = available_claims_data[claim_type].length;
    }
  }

  const handleRoutting = matchedRoute => {
    navigate(`/${usersPolicyNumber}/claimList/${matchedRoute}`);
    setClickedSection(matchedRoute);
  };
  const handleLogoutPolicy = () => {
    window.sessionStorage.clear()
    navigate('/')
  }

  const render_inprogress_claim_list = section => {
    if (available_claims_data[section]&&!available_claims_data[section].length) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
          className="noClaimsFoundimg"
        >
          <img
            src={EmptyStateImg}
            style={{
              width: '284px',
            }}
            alt=""
          />
          <p
            style={{
              color: 'rgb(124, 124, 124)',
              fontWeight: '600',
              fontSize: '18px',
              textAlign: 'center',
            }}
          >
            No Record Found
          </p>
        </div>
      );
    }

    let claims = available_claims_data[section]&&available_claims_data[section].map((claim, i) => {
      return (
        <ClaimStatusCard
          setClaimsData={set_and_shift_to_status}
          current_claim={claim ? claim : null}
          setCashlessPopUpStat={setCashlessPopUpStat}
          key={i}
        />
      );
    });

    return claims;
  };

  const customerName = (xxxx) => {
    if(xxxx['In Progress Claims'].length) {
      return xxxx['In Progress Claims'][0]['claim_data']['proposer_name']
    }else if(xxxx['Approved'].length){
      return xxxx['Approved'][0]['claim_data']['proposer_name']
    }else if(xxxx['Rejected Claims'].length){
      return xxxx['Rejected Claims'][0]['claim_data']['proposer_name']
    }else{
      return ''
    }

  }

  if (available_claims_data) {
    return (
      <>
             { deviceSize<1025?<Header fineText ="Hope You are doing fine" pcText="See your claims below"/>:<></>}

      <div className="claimStatus">
        {/* <div className="top_header_container">
          <div className="top_header_container_1">
            <div className="claimStatus_top">
              <p>
                Hi {` `}
                {customerName(available_claims_data)
                }
              </p>
              {/* <img src={goodByImg} alt="goodByImg" /> */}
            {/* </div> */}
            {/* // <p style={{ color: '#7C7C7C', fontSize: 14 }}> */}
              {/* Hope You are doing fine */}
            {/* </p> */}
          {/* </div> */}
          {/* <img */}
            {/* src={hdfc} */}
            {/* alt="" */}
            {/* style={{ cursor: 'pointer' }} */}
            {/* onClick={() => { */}
              {/* navigate('/policyDetails'); */}
            {/* }} */}
          {/* /> */}
        {/* </div> */}
        {/* <div className='policy_logout' onClick={handleLogoutPolicy} style={{marginTop: '-8px'}}> */}
        {/* <img  src={logoutImg} alt=""/> */}
        {/* </div> */}
        {/* <span */}
          {/* style={{ */}
            {/* fontSize: '16px', */}
            {/* fontWeight: 400, */}
            {/* paddingBottom: '10px', */}
            {/* display: 'inline-block', */}
          {/* }} */}
        {/* > */}
          {/* See your claims below */}
        {/* </span> */} 
        <div className="claimStatus_section3">
          <div className="claimStatus_tab">
            <div
              // onClick={() => setClickedSection('In Progress Claims')}
              onClick={() => handleRoutting('In Progress Claims')}
              className={
                clicked_section === 'In Progress Claims' ? 'activeTabClass' : ''
              }
            >
              <p className="statusText">
                In Progress ({claim_stats['In Progress Claims']})
              </p>
            </div>
            <hr className="hrlineClaimStatus" />
            <div
              // onClick={() => setClickedSection('Approved')}
              // onClick={() => navigate('/claimList/Approved')}
              onClick={() => handleRoutting('Approved')}
              className={clicked_section === 'Approved' ? 'activeTabClass' : ''}
            >
              <p className="statusText">Approved ({claim_stats['Approved']})</p>
            </div>
            <hr className="hrlineClaimStatus" />
            <div
              // onClick={() => setClickedSection('Rejected Claims')}
              onClick={() => handleRoutting('Rejected Claims')}
              className={
                clicked_section === 'Rejected Claims' ? 'activeTabClass' : ''
              }
            >
              <p className="statusText">
                Rejected ({claim_stats['Rejected Claims']})
              </p>
            </div>
          </div>
        </div>
        <div className="claim_section_container">
          {render_inprogress_claim_list(clicked_section)}
          {/* ==================popup=== */}
          {cashlessPopUpStat ? (
            <Modal
              open={cashlessPopUpStat}
              onClose={() => setCashlessPopUpStat(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                {/* <Typography id="modal-modal-title" variant="h6" component="h2">
                Text in a modal
              </Typography> */}

                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  Please visit the Insurance/TPA desk for information related to
                  your cashless claim. The Insurance/TPA desk at the hospital
                  would be able to help you quickly since they have access to
                  your claim on a real-time basis.
                </Typography>
                <button
                  onClick={() => setCashlessPopUpStat(false)}
                  id="close"
                  className="red-close"
                >
                  Ok
                </button>
              </Box>
            </Modal>
          ) : (
            <></>
          )}
        </div>
      </div>
      </>
    );
  }
};

export default ClaimStatus;
