import * as CryptoJS from 'crypto-js';
import { env } from '../config/env';
const crypto = require("crypto");
const secret = "34f%jdf$54"; // Secret Key
const rounds = 9921;
const keySize = 32;
const algorithm = "aes-256-cbc";
const salt = crypto.createHash("sha1").update(secret).digest("hex");

export function aesEncrypt({ data }) {
    const encrypted = CryptoJS.AES.encrypt(data, env.ENCRYPT_DECRYPT_SECRET_KEY);
    return encrypted.toString()
}

export function aesDecrypt({ data }) {
    const decrypted = CryptoJS.AES.decrypt(data.toString(), env.ENCRYPT_DECRYPT_SECRET_KEY);
    return decrypted ? JSON.parse(decrypted.toString(CryptoJS.enc.Utf8)) : "";
}

function encryptData(data) {
    try {
        let iv = crypto.randomBytes(16);
        let key = crypto.pbkdf2Sync(secret, salt, rounds, keySize, "sha512");
        let cipher = crypto.createCipheriv(algorithm, Buffer.from(key), iv);
        let encryptedData = Buffer.concat([
            cipher.update(JSON.stringify(data)),
            cipher.final(),
        ]);
        // const result = decryptData(iv.toString("base64") + ":" + encryptedData.toString("base64"))
        // console.log('result', result)

        return iv.toString("base64") + ":" + encryptedData.toString("base64");
    } catch (err) {
        console.error(err);
        return false;
    }
}

function decryptData(encData) {
    try {
        let textParts = encData.split(":");
        let iv = Buffer.from(textParts.shift(), "base64");
        let encryptedData = Buffer.from(textParts.join(":"), "base64");
        let key = crypto.pbkdf2Sync(secret, salt, rounds, keySize, "sha512");
        let decipher = crypto.createDecipheriv(algorithm, Buffer.from(key), iv);
        let decryptedData = decipher.update(encryptedData);
        decryptedData = Buffer.concat([decryptedData, decipher.final()]);
        return JSON.parse(decryptedData.toString());
    } catch (err) {
        console.error(err);
        return false;
    }
}


export { encryptData, decryptData };
