import React, { useContext, useState, useEffect } from 'react';
import notificationImg from '../../Assets/notificationImg.png';
import CrossIcon from '../../Assets/Cross.png';
import { ClaimContext } from '../../context/ClaimContext';

const NotificationAction = ({setActionRequiredDefaultOpen}) => {
    

  const context = useContext(ClaimContext);

  const [actionRequired, setActionRequired] = useState(context.data.required_documents!==undefined);

  const scrooltoActionRequired = () => {
    window.scroll({
        top: 999, 
        left: 0, 
        behavior: 'smooth'
      });      
      setActionRequiredDefaultOpen(true)
  }


if(actionRequired){
  return (
    <div>
      <div
        className="optima_secure_div_initiated actionRequiredCard"
        style={{ background: 'orange', marginTop: '20px', padding: '4px 12px' }}
      >
        <div className="optima_secure_imgtext">
          <div className="optima_secure_img">
            <img src={notificationImg} alt="" style={{ width: '32px' }} />
          </div>
          <div className="optima_secure_txt">
            <p
              style={{ marginTop: '8px', fontWeight: '500', fontSize: '16px' }}
            >
              Action Required
            </p>
          </div>
          <div className="optima_secure_txt">
            <p
              className="actionRequiredCross"
              onClick={() => setActionRequired(false)}
            >
              <img src={CrossIcon} alt="" />
            </p>
          </div>
        </div>
        <div className="optima_secure_txt_f">
          <p>
            {context.data.required_documents}
          </p>
        </div>
        <div className="optima_secure_txt_s">
          <div></div>
          <p className="actionButton" onClick={scrooltoActionRequired}>Action</p>
        </div>
      </div>
    </div>
  )}else{
    return <></>
  }
};

export default NotificationAction;
