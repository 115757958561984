import { Navigate } from 'react-router-dom';
import { checkAuthorization } from '../utility';



export const ProtectedRoute = ({ children }) => {

  const loggedInUser = checkAuthorization()
  // const loggedInUser = "xxxxx"
  
  return !loggedInUser ? <Navigate to="/" replace="true" /> : children;
};

export const UnProtectedRoute = ({ children }) => {
  
  const loggedInUser = checkAuthorization()

 
  // const loggedInUser = "xxxxx"
  return loggedInUser ? <Navigate to="/policyDetails" replace="true" /> : children;

  // return loggedInUser ? <Navigate to="/claimList/In Progress Claims" replace="true" /> : children;
};
