import React, { useState, useEffect } from 'react';
import './Loader.css';
// import ctrlnLogo from '../../Assets/ctrlnLogo.png';
// import ctrlnLogo from '../../Assets/ctrl+nLogo.svg';
import ctrlnLogo from '../../Assets/CTRL-N_30_10.svg';



import HDFC3Ologo from '../../Assets/HDFC3.Ologo.jpg';
import Loader from './Loader';

const Loader1 = () => {
  const [loading_status, setLoadingStatus] = useState("Loading. \nPlease wait...");



  function loadermessagechange (){
    return new Promise((resolve, reject) =>{
      setTimeout(() => {
        setLoadingStatus(`Please wait! We are fetching your policy details.`);
      }, 3000);
       setTimeout(() => {
        setLoadingStatus(`  Oops! Seems we are taking some more time  `);
      }, 6000);
      setTimeout(() => {
        setLoadingStatus(`  In tough times, we stand with our customers!  `);
      }, 9000);
      setTimeout(() => {
        setLoadingStatus(`Putting all details together now. One moment please!`);
      }, 12000);
      resolve("hi")
    })

  }

  useEffect(() => {
    // setLoadingStatus("Loading. \nPlease wait...");
    loadermessagechange()
  }, []);

  return (
    <div className="loader">
      <div className="sub_loader1">
        <div className="loaderMiddle">
          <img src={HDFC3Ologo} alt="" className="loaderMiddleImg" />
        </div>
        <div className="loaderMiddleText">
          <Loader balls_color="#e41e26"  />
          <p style={{marginTop:'30px',textAlign: 'center', width:'95vw'}}>{loading_status}</p>
        </div>
        <div className="loaderBottom">
          {/* <p>Powered by CTRL-N</p> */}
          <p>Powered by &nbsp;</p>
          <img src={ctrlnLogo} alt="" className="loaderBottomImg" />
        </div>
      </div>
    </div>
  );
};

export default Loader1;
