

import React, { useState } from 'react';
import ReactGA from 'react-ga';
import './Ratings.css';
import Modal from '@mui/material/Modal';
import { env } from '../../config/env';
import showmore from "../../Assets/showmore.svg"
// import showless from "../../Assets/showless.svg"
import showless from "../../Assets/closeIcon.svg"
// import thanksforsubmitting from "../../Assets/thanksforsubmitting.gif"
import thanksforsubmitting from '../../Assets/thanksforsubmittingimg.svg';




const Ratings1 = ({ claimnumber, policynumber, claimstatus }) => {
  // console.log("ratings1", claimnumber, policynumber,claimstatus)

  const [rating, setRating] = useState(0);
  const [popup, setPopup] = useState(false);
  const [openRating, setOpenRating] = useState(true);
  const [openThanks, setOpenThanks] = useState(false);
  const [device, setDevice] = useState((navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i))?"mobile":"desktop")

// console.log("device", device)

  const style = {
    border: 'none!important',
    outline: 'none!important',
  };

  setTimeout(() => {
    setOpenRating(false)
  }, 15000);

  const handleRatings = () => {
    if(rating==0){
      return
    }
    // setRating(val);
    setPopup(true);
  };
  const taptoRatings = () =>{
    setOpenRating(false)
  }
  const ratingClose = () =>{
    setRating(0)
    setPopup(false)
    setOpenThanks(false)
    setOpenRating(true)
  }

  const submitRattings = async (userSatisfied) => {
    // api call
    let data = JSON.stringify({
      encclaimid: claimnumber,
      encpolicyno: policynumber,
      ratings: rating,
      userSatisfied,
      device,
      claimstatus
    });
    // console.log("data", data)
    const requestOptions = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        token: window.sessionStorage.getItem('_ssid'),
      },
      body: data,
    };
    try {
      const res = await fetch(
        `${env.SERVER_URL}/ratings/ratingsbyclaimthroughwhatsapplink`,
        requestOptions
      );
      // console.log('reso', res);
      ReactGA.event({
        category: 'Ratings_by_Whatsapp',
        action: 'Ratings_by_Whatsapp',
        label:'Ratings_by_Whatsapp'
      });
      if(res){
        setOpenThanks(true);
        setTimeout(() => {
         setRating(0)
         setPopup(false)
         setOpenThanks(false)
         setOpenRating(true)
         document.getElementById('taptoRatingid').style.display = 'none';
       }, 5000);
      }
      
    
    } catch (error) {
      console.log('error');
      setOpenThanks(false);
      setOpenRating(false);
      setPopup(false);
    }
    // alert(`rating submitted successfully ${rating}`);
    // console.log('rating submitted', rating);
    // setPopup(false);
  };




  const ThanksModel = () => {
    return (
      // <div className='ratings'>
        <div className="thanksforsubmittingdiv" >
                <p className='ratingClose' onClick={()=>ratingClose()}><img src={showless} alt=""/></p>
          {/* <p className="">Tap To Rating</p> */}
          <img className='thanksforsubmittingimg' src={thanksforsubmitting} alt=""/>
          {/* <p>Thank you for submitting your ratings!</p> */}
          <p>Thank you!</p>
          <p>We appreciate your feedback</p>

      </div>
      // </div>
    );
  };
  const TapToRating = () => {
    return (
      // <div className='ratings'>
        <div className="taptoRatingDiv" id="taptoRatingid" onClick={()=>taptoRatings()}>
          {/* <p className="">Tap To Rating</p> */}
          <p>Feedback</p><img src={showmore} alt=""/>
      </div>
      // </div>
    );
  };
if(openRating){
  return <TapToRating/>
}else if(openThanks){
return (
  <ThanksModel/>
)
}
else{
  return (
    <>
      {/* <div className='showRatingPopup'> */}
      {popup ? (
        <>
        <div className="ratings1">
         {/* <p className="ratingsText ratings1Text">Are you satisfied with our service.</p> */}
         
         <p className="ratingsText ratings1Text">Hope you got what you were looking for!</p>

          <div className="ratingsButtonDiv">
              <button
                onClick={() => submitRattings("no")}
                className="rattingButton rattingButtonCancle"
              >
                No
              </button>
              <button
                onClick={() => submitRattings("yes")}
                className="rattingButton "
              >
                Yes
              </button>
            </div>
      
      </div>
        
        </>
        // <Modal
        //   open={popup}
        //    onClose={handleRatings}
        //   aria-labelledby="modal-modal-title"
        //   aria-describedby="modal-modal-description"
        //   style={{ outline: 'none!important', border: 'none!important' }}
        //   sx={style}
        // >
        //   <div className="ratingsPopupDiv">
        //     <p className="ratingsTextCnf">Are you satisfied with this application?</p>
        //     <div className="ratingsButtonDiv">
        //       <button
        //         onClick={() => submitRattings()}
        //         className="rattingButton "
        //       >
        //         Submit
        //       </button>
        //       <button
        //         onClick={() => setPopup(false)}
        //         className="rattingButton rattingButtonCancle"
        //       >
        //         Cancle
        //       </button>
        //     </div>
        //   </div>
        // </Modal>
      ) : (
        <>
            <div className="ratings">
      {/* <p className="ratingsHeaders">Ratings</p> */}
      <p className='ratingClose' onClick={()=>ratingClose()}><img src={showless} alt=""/></p>
      {/* <p className="ratingsText">Please rate us basis your visit on this claim tracker journey.</p> */}
      <p className="ratingsText">
                Please take a moment to rate your journey experience. Thanks for
                support
              </p>

      <div className="ratingsBoxDiv">
        <div
          className={`ratingsBox ${rating == 1 ? 'selectedColor' : ''}`}
          onClick={() => setRating(1)}
        >
          1
        </div>
        <div
          className={`ratingsBox ${rating == 2 ? 'selectedColor' : ''}`}
          onClick={() => setRating(2)}
        >
          2
        </div>
        <div
          className={`ratingsBox ${rating == 3 ? 'selectedColor' : ''}`}
          onClick={() => setRating(3)}
        >
          3
        </div>
        <div
          className={`ratingsBox ${rating == 4 ? 'selectedColor' : ''}`}
          onClick={() => setRating(4)}
        >
          4
        </div>
        <div
          className={`ratingsBox ${rating == 5 ? 'selectedColor' : ''}`}
          onClick={() => setRating(5)}
        >
          5
        </div>
        <div
          className={`ratingsBox ${rating == 6 ? 'selectedColor' : ''}`}
          onClick={() => setRating(6)}
        >
          6
        </div>
        <div
          className={`ratingsBox ${rating == 7 ? 'selectedColor' : ''}`}
          onClick={() => setRating(7)}
        >
          7
        </div>
        <div
          className={`ratingsBox ${rating == 8 ? 'selectedColor' : ''}`}
          onClick={() => setRating(8)}
        >
          8
        </div>
        <div
          className={`ratingsBox ${rating == 9 ? 'selectedColor' : ''}`}
          onClick={() => setRating(9)}
        >
          9
        </div>
        <div
          className={`ratingsBox ${rating == 10 ? 'selectedColor' : ''}`}
          onClick={() => setRating(10)}
        >
          10
        </div>
      </div>
      <div className="Rating_progress">
        <p className="Rating_progress_r"></p>
        <p className="Rating_progress_y"></p>
        <p className="Rating_progress_g"></p>
      </div>
      <div className="Rating_progress_text">
        <p>Average</p>
        <p>Good</p>
        <p>Excellent</p>
      </div>
      <button className='ratingSubmitBtn'  onClick={() => handleRatings()}>Submit</button>
      </div>
        </>
      )}
      {/* </div> */}
    {/* </div> */}

    </>
    
  );
      }
      
};

export default Ratings1;


