import React, { useState, useContext, useEffect } from 'react';
// import uparrow from '../../Assets/uparrow.svg';
import downarrow from '../../Assets/downarrow.svg';
// import claimstatus from '../../Assets/claim-status.svg';
import claimstatus from '../../Assets/documentDetailsPng.png';

import greentick from '../../Assets/green-tick.svg';
import pending from '../../Assets/pending.svg';
import './DocumentStatus.css';
import DocumentUpload from '../DocumentUpload/DocumentUpload';
import Skeleton from 'react-loading-skeleton';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { ClaimContext } from '../../context/ClaimContext';
import Loader from '../Loader/Loader';
import Insertphoto from '../../Assets/Insertphoto.svg';
import pdfImg from '../../Assets/Icon/PDF.svg';
import viewImg from '../../Assets/Icon/On.svg';
import downloadImg from '../../Assets/Icon/Download.svg';
import infoImg from '../../Assets/Icon/Info.svg';
import { env } from '../../config/env';
import ReactGA from 'react-ga';



const DocumentStatus = props => {

  // console.log('DocumentStatus', props)


  const context = useContext(ClaimContext);
  // if(context.data.required_documents!==undefined){
  //   window.scroll({
  //     top: 700,
  //     behavior: 'smooth'
  //   });
  // }

  const [loading, setLoading] = useState(false);
  const [clicked_index, setClickedIndex] = useState(null);
  let [clDocs, setClDocs] = useState([]);



  const documentDownload = (docid, doc_name, i) => {
    setClickedIndex(i);
    setLoading(true);

    const requestOptions = {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        token: window.sessionStorage.getItem('_ssid'),
      },
    };

    fetch(`${env.SERVER_URL}/download_document/${docid}`, requestOptions)
      .then(res => {
        return res.blob();
      })
      .then(data => {
        // console.log('downloadData', data);
        if(data.type =='application/json'){
          data = new Blob([data], { type: "application/pdf" });
        let a = document.createElement('a');
        a.href = window.URL.createObjectURL(data);
        a.download = doc_name;
        a.click();
        }
        setLoading(false);
        setClickedIndex(null);
        let mobile = localStorage.getItem('mobile');
        ReactGA.event({
          category: 'Document_Download',
          action: 'Document_Download',
          label:'Document_Download'
        });
        
      });
  };

  function clDocsFromDoc() {
    let clDocsArray = [];
    if (context?.documents?.length > 0) {
      context.documents.map(function (element, index) {
        if (element['a:DOCTYPE_TXT']._text !== 'Letter') {
          clDocsArray.push(element);
        }
      });
      setClDocs(clDocsArray);
    }
  }

  useEffect(() => {
    clDocsFromDoc();
  }, [])


  if (context) {
    // console.log('context', context.data.required_documents);
    return (
      <>
        <Accordion className='accordianContainer'>
          <AccordionSummary
            aria-controls="panel1a-content"
            expandIcon={<img src={downarrow} alt="" />}
            id="panel1a-header"
          >
            <div className="document-status-sub">
              <img src={claimstatus} alt="" />
              <p className="document-status-text">
                <span className="heading document_status">
                  Document Status
                  {/* {context.data.required_documents ? (
                    <img
                      className="pending_status"
                      src={pending}
                      alt="pending"
                    />
                  ) : (
                    <></>
                  )} */}
                </span>
                Track your document
              </p>
            </div>
          </AccordionSummary>
          <hr />
          <AccordionDetails>
            <div>
              <div className="DocumentUploadPart">
                {clDocs?.length>0 ? (
                  clDocs.map(function (element, i) {
                    if (element['a:DOCTYPE_TXT']._text !== 'Letter') {
                      return (
                        // ++++++++++++++++

                        <div className="documentVisibleSection" key={i}>
                          <div className="documentVisibleSectionLs">
                            <img
                              src={
                                element['a:STOREDTYPE_TXT']._text ==
                                'application/pdf'
                                  ? pdfImg
                                  : Insertphoto
                              }
                              className="DocumentUploadPartImg"
                            />

                            <div className="details_copntainer_documents">
                              <span className="document_type">
                                {element['a:DOCTYPE_TXT']._text}
                              </span>
                              <br />
                              <span className="document_date">
                                {element['a:CREATED_DATE']._text}
                              </span>
                            </div>
                          </div>

                          <div className="documentVisibleSectionRs">
                            {/* <div className="DocumentUploadPartImgDiv">
                             <img src={viewImg} /> 
                          </div>  */}
                            <div></div>
                            {loading && clicked_index === i ? (
                              <Loader balls_color="grey" />
                            ) : (
                              <div
                                className="DocumentUploadPartImgDiv"
                                onClick={() =>
                                  documentDownload(
                                    element['a:DOCID_NUM']._text,
                                    element['a:DOCTYPE_TXT']._text,
                                    i
                                  )
                                }
                              >
                                <img src={downloadImg} />
                              </div>
                            )}
                          </div>
                        </div>
                        // ======================================================
                        // <span className="small-div" key={i}>
                        //   <img src={greentick} alt="" />
                        //   <span style={{lineHeight:"1.7",padding:"6px 0px"}}>{element['a:DOCTYPE_TXT']._text}</span>
                        //   <span style={{lineHeight:"1.7",padding:"6px 0px"}}>{element['a:CREATED_DATE']._text}</span>
                        // </span>
                      );
                    }
                  })
                ) : (
                  <p className="noclaimletters">No docuement found</p>
                )}
              </div>  
              {/* ================================================================ select accordian*/}

              {
                // <span>
                //   {props.status === 'Claim Rejected' ||
                //   props.status === 'Bill verification' ||
                //   props.status === 'Claim Initiated' ||
                //   props.status === 'Document Verification' ? (
                //     <DocumentUpload {...props} />
                //   ) : (
                //     <div></div>
                //   )}
                //   {/* <DocumentUpload {...props} /> */}
                // </span>
              }
            </div>
          </AccordionDetails>
        </Accordion>
      </>
    );
  } else if (props.documentsError) {
    return <></>;
  } else {
    return (
      <></>
      // <div className="claim-details">
      //   <div className="claim-details-sub">
      //     <Skeleton width="3rem" height="3rem" circle={false} count={1} />
      //     <p className="claim-details-text">
      //       <span className="heading">
      //         <Skeleton width="8rem" height="1rem" circle={false} count={1} />
      //       </span>
      //       <br />
      //       <Skeleton width="8rem" height="0.4rem" circle={false} count={1} />
      //     </p>
      //   </div>
      //   <div className="float-right">
      //     <Skeleton width="0.8rem" height="0.8rem" circle={false} count={1} />
      //   </div>
      // </div>
    );
  }
};

export default DocumentStatus;

{
  /* <section className="document-status-main">
          <div className="document-status" onClick={() => setShow(!show)}>
            <div className="document-status-sub">
              <img src={claimstatus} alt="" />
              <p className="document-status-text">
                <span className="heading">Document Status</span> <br />
                Track your document status
              </p>
            </div>
            <div className="float-right">
              {show ? (
                <img src={uparrow} alt="" />
              ) : (
                <img src={downarrow} alt="" />
              )}
            </div>
          </div>
          <div>
            <div
              className={
                show ? "document-status-inner" : "document-status-hide"
              }
            >
              <hr />
              <div>
                {context.documents.map(function (element, i) {
                  return (
                    <span className="small-div" key={i}>
                      <img src={greentick} alt="" />
                      <p>{element["a:DOCUMENTNAME_TXT"]._text}</p>
                    </span>
                  );
                })}
                <span>
                  <DocumentUpload />
                </span>
              </div>
            </div>
          </div>
        </section> */
}
