import React, { useContext, useState } from 'react';
import './InitiateCard.css';
import DocumentUpload from '../../Components/DocumentUpload/DocumentUpload';
import { useNavigate } from 'react-router-dom';

import { ClaimContext } from '../../context/ClaimContext';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import optimaSecureImg from '../../Assets/optima_secure.svg';
import notificationImg from '../../Assets/notificationImg.png';
import CrossIcon from '../../Assets/Cross.png';




const CardYellow = () => {
  const context = useContext(ClaimContext);

 // if(context.data.required_documents!==undefined){
  //   window.scroll({
  //     top: 700,
  //     behavior: 'smooth'
  //   });
  // }



  const [actionRequired, setActionRequired] = useState(context.data.required_documents!==undefined)
  // console.log(context.data);

  const navigate = useNavigate();

  // const documentUpload = () => {
  //   navigate('/documentUpload');
  // };

  if (context.data) {
    return (
      <>
        <section className="card-initiate-mainN">
          {/* <div className="card-content">
            <div className="initiate-card-box">
              <p className="initiate-card-amount">
                &#8377; {context.data.total_claimamount}/-
              </p>
              <p className="initiate-less-bold">Claim Amount Filed</p>
            </div>
          </div>
          <div className="initiate-card-status">
            <p className="initiate-semi-bold">
              <span className="initiate-less-bold">Claim Id </span>
              <span className="initiate-semi-bold"> {context.data.claim_id}</span>
            </p>
            <p className="initiate-semi-bold">
              <span className="initiate-less-bold">Status </span>
              <span className="initiate-semi-bold"> Claim Initiated</span>
            </p>
          </div> */}

          <div className="optima_secure_div_initiated">
            <div className="optima_secure_imgtext">
              <div className="optima_secure_img">
                <img src={optimaSecureImg} alt="" />
              </div>
              <div className="optima_secure_txt">
                <p>{context.data.product_name.substr(0, 50)}</p>
                <p>{context.data.policy_number}</p>
              </div>
            </div>
            <div className="optima_secure_txt_f">
              <p>Claim Id</p>
              <p>Status</p>
            </div>
            <div className="optima_secure_txt_s">
              <p>{context.data.claim_id}</p>
              <p>
                <span className="doc_ver_dot"></span>
                {context.data.claim_status === 'Verification'
                  ? 'Document Verification'
                  : context.data.claim_status}
              </p>
            </div>
            {/* <hr/> 
           <div className="optima_secure_txt_l">
        <p>Claim Amount Filed</p>
        <p>₹ {context.data.total_claimamount}/-</p>
      </div> */}
          </div>

        </section>
      </>
    );
  } else {
    return (
      <>
        <section className="card-main-skeleton">
          <Skeleton height="8.5rem" circle={false} count={1} />
        </section>
      </>
    );
  }
};

export default CardYellow;