import React from 'react'
import './CardRed.css'
// import Modal from "react-modal";
import { useContext } from 'react'
import { ClaimContext } from '../../context/ClaimContext'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import optimaSecureImg from '../../Assets/optima_secure.svg';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '96%',
  maxWidth: '423px',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '4px',
  boxShadow: 24,
  p: 4,
  display:'flex',
  flexDirection: 'column',
  alignItems: 'center',
  overflow:'scroll',
  // height: '460px',
  maxHeight: '460px',
}
const CardRed = () => {
  const context = useContext(ClaimContext)

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    }
  }

  let subtitle
  const [modalIsOpen, setIsOpen] = React.useState(false)

  function openModal () {
    setIsOpen(true)
  }

  function afterOpenModal () {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00'
  }

  function closeModal () {
    setIsOpen(false)
  }

  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  if (context.data) {
    return (
      <>
        <section className='card-red-mainN'>
          {/* <div className='red-card-content'>
            
          <div className='box1'>
                <span className='semi-bold'>
                  <p className='card-amount'>
                    &#8377;{context.data.total_claimamount}/-
                  </p>
                </span>
                <p>Claim Amount Filed</p>
              </div>
              <div className='box2'>
                <span className='semi-bold'>
                  <p className='card-amount'>
                    &#8377; {context.data.approved_amt}/-
                  </p>
                </span>
                <p>Rejected Claim Amount</p>
              </div>
           
          </div>
          <div className='red-card-status'>
            <p className='red-semi-bold'>
              <span className='yellow-less-bold'>Claim Id </span>
              <span className='yellow-semi-bold'>{context.data.claim_id}</span>
            </p>
            <p className='red-semi-bold'>
              <span className='red-dot' />
              <span className='yellow-less-bold'>Status</span>

              <span onClick={handleOpen} className='yellow-semi-bold link'>
                Claim Rejected
              </span>
            </p>
          </div> */}
          {/* ========================== */}
           <div className="optima_secure_div_red">
            <div className="optima_secure_imgtext">
              <div className="optima_secure_img">
                <img src={optimaSecureImg} alt="" />
              </div>
              <div className="optima_secure_txt">
                <p>{context.data.product_name.substr(0, 50)}</p>
                <p>{context.data.policy_number}</p>
              </div>
            </div>
            <div className="optima_secure_txt_f">
              <p>Claim Id</p>
              <p>Status</p>
            </div>
            <div className="optima_secure_txt_s">
              <p>{context.data.claim_id}</p>
              <p className={`${context.data.reason_for_rejection ?'link1':''}`} onClick={context.data.reason_for_rejection?handleOpen:null}>
                <span className="doc_ver_dot"></span>{' '}
                {context.data.claim_status === 'Rejected'
                  ? 'Claim Rejected'
                  : context.data.claim_status}
                  <span className={ ` ${context.data.reason_for_rejection ?'tooltiptext':'link2'}`}>Reason for Rejection</span>
              </p>
              
            </div>
            {/* <hr />
            <div className="optima_secure_txt_l">
              <p>
                {context.data.claim_status === 'Approved'
                  ? 'Amount Approved'
                  : 'Amount Approved & Disbursed'}
              </p>
              <p>₹ {context.data.approved_amt}/-</p>
            </div> */}
          </div>

        </section>

        <div>
          {/* <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <div className="red-disclosure">
              Non-Disclosure of Medical History...
            </div>
            <button onClick={closeModal} className="red-close">
              Ok
            </button>
          </Modal> */}
          {context.data.reason_for_rejection?
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <Box sx={style}>
              {/* <Typography id="modal-modal-title" variant="h6" component="h2">
                Text in a modal
              </Typography> */}
           
              <Typography id='modal-modal-description' sx={{ mt: 2 }}>
                
                {context.data.reason_for_rejection}

              </Typography>
              <button onClick={handleClose} id='close' className='red-close'>
                  Ok
              </button>
            </Box>
          </Modal>:<></>}
        </div>
      </>
    )
  } else {
    <section className='card-main-skeleton'>
      <Skeleton height='8.5rem' circle={false} count={1} />
    </section>
  }
}

export default CardRed
