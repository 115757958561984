import React, { useEffect, useState } from 'react';
import './Otp.css';
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';
import iconEdit from '../../Assets/Icon-edit.svg';
import { AlertComponent } from '../Alert/Alert';
import { env } from '../../config/env';
import Loader from '../Loader/Loader';
import ReactGA from 'react-ga';
import { decryptData, encryptData } from '../../utils/Cryto';
import axios from 'axios';

const Otp = ({ mobile, submit }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userInput, setUserInput] = useState('');
  // const [otp, setOtp] = useState('');
  const [remain, setRemain] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [resendButton, setresendButton] = useState(false);

  const [alertStatus, setAlertStatus] = useState({
    status: false,
    msg: null,
    warning_status: '',
  });

  const clearAlert = () => {
    setAlertStatus({ status: false, msg: null, warning_status: '' });
  };

  const setAlert = alert => {
    setAlertStatus(alert);
  };

  const inputchangehandler = event => {
    setUserInput(event);
    if (event.length === 4) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  };

  // console.log(mobile);
  const [seconds, setSeconds] = useState(60);

  const gotoHome = () => {
    navigate('/');
  };

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
      setRemain(false);
    } else {
      setSeconds('');
      setRemain(true);
    }
    return () => { };
  }, [seconds, resendButton]);

  const Resent = async () => {
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      // mode: 'no-cors',
    };
        try {
      // const res = await fetch(
      //   `${env.SERVER_URL}/auth/get_otp/${mobile}`,
      //   requestOptions
      // );

      const res = await axios.get(`${env.SERVER_URL}/auth/get_otp/${mobile}`)
      
      const final_response = res

      if(final_response?.data?.data === "Otp limit exceeded"){
        setAlert({
          status: true,
          msg: "Otp limit exceeded, please try again after 15 minutes",
          warning_status: 'success',
        });
        setTimeout(() => {
          // navigate('/validation');
          clearAlert();
        }, 3000);
        // console.log(ress);
        return
      }

      // console.log(final_response);
      // setData(final_response.data);
      // console.log("otp1")
      ReactGA.event({
        category: 'Resent_Otp',
        action: 'Resent_Otp',
        label: 'Resent_Otp'
      });
      // console.log("otp2")

    } catch (error) {
      console.log(error);
      // setError(true);
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (!navigator.onLine) {
      return;
    }
    const val = userInput;
    if (val.length !== 4) {
      return;
    } else {
      const data = { mobileno: mobile, otp: val };
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: (JSON.stringify({data: encryptData(data)})),
      };

      try {
                const res = await fetch(
          `${env.SERVER_URL}/auth/verify_otp`,
          requestOptions
        );
        let final_response = await res.json();
        // Decrypt
        const tempfinal_responseDecrypted = await decryptData(final_response)
                if (tempfinal_responseDecrypted) {
          final_response = tempfinal_responseDecrypted
        }
        // console.log('final_response >> ', final_response);
        if (final_response.data === 'successfully verified') {
          const token = encryptData(final_response.token)
          // window.sessionStorage.setItem('_ssid', final_response.token);
          window.sessionStorage.setItem('_ssid', token);
          setBtnDisable(false);
          // navigate('/policyDetails');
          setAlert({
            status: true,
            msg: 'Verified Successfully',
            warning_status: 'success',
          });
          setTimeout(() => {
            navigate('/policyDetails');
            clearAlert();
          }, 3000);
        }

        if (final_response.data === 'OTP Expired') {
          const token = encryptData(final_response.token)
          // window.sessionStorage.setItem('_ssid', final_response.token);
          window.sessionStorage.setItem('_ssid', token);
          setBtnDisable(false);
          // navigate('/policyDetails');
          setAlert({
            status: true,
            msg: 'OTP Expired',
            warning_status: 'success',
          });
          setTimeout(() => {
            navigate('/policyDetails');
            clearAlert();
          }, 3000);
        }

        if (final_response.data === 'Not Verified') {
          // handle this
          setBtnDisable(false);
          setBtnDisable(false);
          setUserInput('');
          setAlert({
            status: true,
            msg: 'Please enter correct OTP',
            warning_status: 'error',
          });
          setTimeout(() => {
            clearAlert();
          }, 4000);
        }
        if (final_response.data === 'Otp limit exceeded') {
          // handle this
          setBtnDisable(false);
          setBtnDisable(false);
          setresendButton(true)
          setUserInput('');
          setAlert({
            status: true,
            msg: 'Otp limit exceeded, please try again after 24 hrs.',
            warning_status: 'error',
          });
          setTimeout(() => {
            clearAlert();
          }, 4000);
        }
      } catch (error) {
        console.log(error);
        // setError(true);
      }
      // setLoading(true);
      // let start = Date.now()
      // const res = await fetch(
      //   `${env.SERVER_URL}/auth/verify_otp`,
      //   requestOptions
      // );
      // const final_response = await res.json();
      // window.sessionStorage.setItem('_ssid', final_response.token);
      // console.log("otp1")

      ReactGA.event({
        category: 'otp_fetched',
        action: 'otp_fetched',
        label: 'otp_fetched'
      });
      // console.log("otp1")

      // setBtnDisable(false);
      // setLoading(false);
      // setAlert({
      //   status: true,
      //   msg: 'Verified Successfully',
      //   warning_status: 'success',
      // });
      // setTimeout(() => {
      //   // navigate('/claimList/In Progress Claims');
      //   navigate('/policyDetails')
      //   clearAlert();
      // }, 3000);
    }
  };

  return (
    <>
      <section className="otp-main">
        <div className="otp-sub">
          <p className="login-text">Confirm OTP</p>
          <p className="otp-text">
            OTP has been sent to you on your mobile number
          </p>
          <p className="no">
            <span>+91 {mobile}</span>
            <img onClick={gotoHome} src={iconEdit} alt="" />
          </p>

          <div className="otp-box">
            <OtpInput
              inputStyle={{
                width: '4em',
                height: '3.5em',
                border: '1px solid lightgrey',
                borderRadius: 5,
                fontWeight: 'bold',
                fontSize: 16,
                color: '#e41e26',
              }}
              name="otp"
              value={userInput}
              onChange={inputchangehandler}
              numInputs={4}
              separator={<span />}
              isInputNum="true"
              shouldAutoFocus="true"
            />
          </div>
        </div>

        <div id="sign-in-button" />

        {btnDisable && (
          <button
            onClick={!loading ? handleSubmit : null}
            className="otp-button"
          >
            {loading ? <Loader balls_color="white" /> : 'Verify'}
          </button>
        )}
        {!btnDisable && (
          <button
            disabled
            style={{ backgroundColor: 'grey' }}
            className="otp-button"
          >
            {loading ? <Loader balls_color="white" /> : 'Verify'}
          </button>
        )}

        {!remain && !resendButton && <div className="otp-count">Resend OTP : {seconds}</div>}
        {remain && !resendButton &&(
          <div className="resend-otpp">
            <span>Didn't receive an OTP?</span>
            <p
              className="resent"
              onClick={() => {
                Resent();
                setSeconds(60);
              }}
            >
              Resend
            </p>
          </div>
        )}

        {alertStatus.status ? (
          <div>
            <AlertComponent alert_status={alertStatus} />
          </div>
        ) : (
          <></>
        )}
      </section>
    </>
  );
};

export default Otp;
