import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClaimContext } from '../../context/ClaimContext';
import './Mainapp.css';
import Story from '../Stories/Stories';
import CardGreen from '../CardGreen/CardGreen';
import CardYellow from '../CardYellow/CardYellow';
import CardRed from '../CardRed/CardRed';
import BankDetails from '../BankDetails/BankDetails';
import BankDetailsDesktop from '../BankDetails/BankDetailsDesktop';
import { ShowBankDetails } from '../BankDetails/BankDetailsDesktop';

import InitiateCard from '../InitiateCard/InitiateCard';
import ClaimStatusTracker from '../ClaimStatusTracker/ClaimStatusTracker';
import ClaimDetails from '../ClaimDetails/ClaimDetails';
import ClaimDetailsDesktop from '../ClaimDetails/ClaimDetailsDesktop';
import { ShowClaimDetails } from '../ClaimDetails/ClaimDetailsDesktop';

import DocumentStatus from '../DocumentStatus/DocumentStatus';
import DocumentStatusDesktop from '../DocumentStatus/DocumentStatusDesktop';
import { ShowDocumentStatus } from '../DocumentStatus/DocumentStatusDesktop';

import ActionRequired from '../ActionRequired/ActionRequired';
import ActionRequiredDesktop from '../ActionRequired/ActionRequiredDesktop';
import { ShowActionRequired } from '../ActionRequired/ActionRequiredDesktop';

import Ratings from '../Ratings/Ratings';

import ClaimAmount from '../ClaimAmount/ClaimAmount';
import ClaimLetter from '../ClaimLetter/ClaimLetters';
import ClaimLettersDesktop from '../ClaimLetter/ClaimLettersDesktop';
import { ShowClaimLetters } from '../ClaimLetter/ClaimLettersDesktop';

import { AlertComponent } from '../Alert/Alert';
import FeedBack from '../FeedBack/FeedBack';
import ServerDown from '../../Assets/server-down.png';
import 'react-loading-skeleton/dist/skeleton.css';
import { env } from '../../config/env';

import Loader from '../Loader/Loader';
import NotificationAction from '../NotificationAction/NotificationAction';
import Header from '../Header/Header';

const status_obj = {
  'Under Process': 'Verification',
  'Deny Request': 'Rejected',
  'Claim Repudiated': 'Rejected',
  'Claim Payment': 'Disbursed',
  'Reimb Supplementry claim registered': 'Initiated',
  'Reimbursement Supplementry claim registered': 'Initiated',
  'Reimbursement claim registered': 'Initiated',
  'Intimation Received': 'Initiated',
  'Query Request': 'Verification',
  'Claim Approved': 'Approved',
  'Claim Documents Received': 'Verification',
  'Claims Doc Received': 'Verification',
  'Claim Disbursed': 'Disbursed',
  'Supplementry Claim Request': 'Initiated',
  'New Request': 'Initiated',
  'Change Request By Medico': 'Verification',
  'Additional documents request': 'Verification',
};

const MainApp = ({ setClaims, current_claim, updateIntimationStatus }) => {
  // window.scrollTo({ top:0, left: 0 });

  const context = useContext(ClaimContext);

  useEffect(() => {
    window.scroll({
      top: -100,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [unauthorized, setUnauthorized] = useState(false);
  const [actionRequiredDefaultOpen, setActionRequiredDefaultOpen] =
    useState(false);

  const [errStatus, setErrStatus] = useState(false);
  const [selectedTab, setSelectedTab] = useState('claimdetails');

  const [alertStatus, setAlertStatus] = useState({
    status: false,
    msg: null,
    warning_status: '',
  });




  

  const clearAlert = () => {
    setAlertStatus({ status: false, msg: null, warning_status: '' });
    document.getElementsByTagName('body')[0].style.height = 'auto';
    document.getElementsByTagName('body')[0].style.overflow = 'scroll';
  };
  const setAlert = alert => {
    setAlertStatus(alert);
    document.getElementsByTagName('body')[0].style.height = '100vh';
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
  };

  const update_progress = progress => {
    if (progress > 0) {
      document.getElementsByClassName('alert_progress')[0].innerHTML = `${
        progress.toString().split('.')[0]
      }%`;
    } else {
      document.getElementsByClassName('alert_progress')[0].innerHTML = ``;
    }
  };

  const [documents, setDocuments] = useState(null);
  const [documentsError, setDocumentsError] = useState(false);
  const [loading_status, setLoadingStatus] = useState('Please wait...');

  useEffect(() => {
    let cached_claim = window.sessionStorage.getItem('claim');
    if (!cached_claim) {
      return navigate('/claimList');
    }
    setClaims(JSON.parse(cached_claim), () => null);
  }, []);


  useEffect(() => {
    if(actionRequiredDefaultOpen){
      setSelectedTab('actionrequired')
    }
   
  }, [actionRequiredDefaultOpen]);

  if (error) {
    return (
      <div className="server-down">
        <img src={ServerDown} alt="" />
      </div>
    );
  }

  if (unauthorized) {
    return (
      <div className="server-down">
        <img src={ServerDown} alt="" />
        <p>Your session is expired. Please Login again</p>
      </div>
    );
  }

  if (current_claim) {
    let { claim_data, bank_data, document_data } = current_claim;
    return (
      <div className="main_cx_container">
        <ClaimContext.Provider
          value={{
            data: claim_data,
            updateIntimationStatus,
            documents: document_data,
            bank_data,
          }}
        >
          <Header
            name={claim_data.proposer_name}
            fineText="Hope you are doing fine"
          />
          <div className="mainAppContainer">
            <Story />
            <div className="mainAppContainerCard">
              {claim_data && claim_data.claim_status === 'Claim Rejected' ? (
                <CardRed />
              ) : (claim_data &&
                  claim_data.claim_status === 'Claim Approved') ||
                (claim_data && claim_data.claim_status === 'Claim Paid') ||
                (claim_data && claim_data.claim_status === 'Claim Closed') ||
                (claim_data && claim_data.claim_status === 'Close') ? (
                <CardGreen />
              ) : (claim_data &&
                  claim_data.claim_status === 'Bill verification') ||
                (claim_data &&
                  claim_data.claim_status === 'Document Verification') ||
                (claim_data &&
                  claim_data.claim_status === 'Documents Verification') ||
                (claim_data &&
                  claim_data.claim_status === 'Additional documents request') ||
                (claim_data &&
                  claim_data.claim_status === 'Document awaited') ||
                (claim_data && claim_data.claim_status === 'Quality check') ? (
                <CardYellow />
              ) : (
                <InitiateCard />
              )}
            </div>
          </div>

          <NotificationAction
            setActionRequiredDefaultOpen={setActionRequiredDefaultOpen}
          />
          <div className="ratingPlusTracker">
            <div></div>
            {/* <Ratings claimnumber={claim_data.claim_id} policynumber={claim_data.policy_number}/> */}
            {/* <div className='ClaimStatusInformation'>
          <p className='ClaimStatusInformationP'>Claims Status <span className='blink_me'>Updated</span></p>
          <p className='ClaimStatusInformationP1'>Document Verification - Sample Text : HDFC ERGO will scrutinize all documents and approve the claim. In case additional information or documents are required, HDFC ERGO will call for the same and upon satisfactory receipt of all necessary documents the claim will be settled by HDFC ERGO</p>
         </div> */}
            {claim_data ? (
              <ClaimStatusTracker statu={claim_data.claim_status} />
            ) : (
              <div></div>
            )}
            <div></div>
          </div>

          <div className="claimDetailsForMobile">
            <div className="mainAppDetails">
              <div className="mainAppDetailsSection">
                <ClaimDetails />
              </div>

              {claim_data && claim_data.claim_status ? (
                <div className="mainAppDetailsSection">
                  {claim_data.claim_type == 'PREAUTH' ||
                  claim_data.claim_type == 'Cashless' ||
                  claim_data.claim_type == 'CASHLESS'||claim_data.claim_type=='PRE-AUTH DISCHARGE' ? (
                    <></>
                  ) : (
                    <DocumentStatus
                      setAlert={setAlert}
                      clearAlert={clearAlert}
                      status={claim_data.claim_status}
                      documentsError={documentsError}
                      update_progress={update_progress}
                    />
                  )}
                </div>
              ) : (
                <></>
              )}
              <div className="mainAppDetailsSection">
                {claim_data.claim_type == 'PREAUTH' ||
                claim_data.claim_type == 'Cashless' ||
                claim_data.claim_type == 'CASHLESS'||claim_data.claim_type=='PRE-AUTH DISCHARGE' ? (
                  <></>
                ) : (
                  <ActionRequired
                    actionRequiredDefaultOpen={actionRequiredDefaultOpen}
                    setAlert={setAlert}
                    clearAlert={clearAlert}
                    status={claim_data.claim_status}
                    documentsError={documentsError}
                    update_progress={update_progress}
                  />
                )}
              </div>
              {/* Letters for claims if available */}
              <div className="mainAppDetailsSection">
                {claim_data.claim_type == 'PREAUTH' ||
                claim_data.claim_type == 'Cashless' ||
                claim_data.claim_type == 'CASHLESS'||claim_data.claim_type=='PRE-AUTH DISCHARGE' ? (
                  <></>
                ) : (
                  <ClaimLetter
                    setAlert={setAlert}
                    clearAlert={clearAlert}
                    status={claim_data.claim_status}
                    documentsError={documentsError}
                    update_progress={update_progress}
                  />
                )}
              </div>
              {/* <DocumentStatus /> */}
              {/* <ClaimAmount /> */}
              <div className="mainAppDetailsSection">
                {claim_data.claim_type == 'PREAUTH' ||
                claim_data.claim_type == 'Cashless' ||
                claim_data.claim_type == 'CASHLESS'||claim_data.claim_type=='PRE-AUTH DISCHARGE' ? (
                  <></>
                ) : (
                  <>{bank_data ? <BankDetails /> : <></>}</>
                )}
                {/* <FeedBack /> */}
              </div>
            </div>
          </div>
          <div
            className={`claimDetailsForDesktop ${
              claim_data.claim_type == 'PREAUTH' ||
              claim_data.claim_type == 'Cashless' ||
              claim_data.claim_type == 'CASHLESS'||claim_data.claim_type=='PRE-AUTH DISCHARGE'
                ? 'claimDetailsForDesktopPauth'
                : ''
            }`}
          >
            {claim_data.claim_type == 'PREAUTH' ||
            claim_data.claim_type == 'Cashless' ||
            claim_data.claim_type == 'CASHLESS'||claim_data.claim_type=='PRE-AUTH DISCHARGE' ? (
              <></>
            ) : (
              <div className="mainAppDetails">
                <div
                  className={`mainAppDetailsSection ${
                    selectedTab == 'claimdetails' ? 'selected-tab-color' : ''
                  }`}
                  onClick={() => {
                    setSelectedTab('claimdetails');
                  }}
                >
                  <ClaimDetailsDesktop />
                </div>
                {claim_data && claim_data.claim_status ? (
                  <div
                    className={`mainAppDetailsSection ${
                      selectedTab == 'documentdetails'
                        ? 'selected-tab-color'
                        : ''
                    }`}
                    onClick={() => {
                      setSelectedTab('documentdetails');
                    }}
                  >
                    <DocumentStatusDesktop
                      setAlert={setAlert}
                      clearAlert={clearAlert}
                      status={claim_data.claim_status}
                      documentsError={documentsError}
                      update_progress={update_progress}
                    />
                  </div>
                ) : (
                  <></>
                )}
                <div
                  className={`mainAppDetailsSection ${
                    selectedTab == 'actionrequired' ? 'selected-tab-color' : ''
                  }`}
                  onClick={() => {
                    setSelectedTab('actionrequired');
                  }}
                >
                  <ActionRequiredDesktop
                    actionRequiredDefaultOpen={actionRequiredDefaultOpen}
                    setAlert={setAlert}
                    clearAlert={clearAlert}
                    status={claim_data.claim_status}
                    documentsError={documentsError}
                    update_progress={update_progress}
                  />
                </div>
                {/* Letters for claims if available */}
                <div
                  className={`mainAppDetailsSection ${
                    selectedTab == 'claimletters' ? 'selected-tab-color' : ''
                  }`}
                  onClick={() => {
                    setSelectedTab('claimletters');
                  }}
                >
                  <ClaimLettersDesktop
                    setAlert={setAlert}
                    clearAlert={clearAlert}
                    status={claim_data.claim_status}
                    documentsError={documentsError}
                    update_progress={update_progress}
                  />
                </div>
                {/* <DocumentStatus /> */}
                {/* <ClaimAmount /> */}
                <div
                  className={`mainAppDetailsSection ${
                    selectedTab == 'bankdetails' ? 'selected-tab-color' : ''
                  }`}
                  onClick={() => {
                    setSelectedTab('bankdetails');
                  }}
                >
                  <BankDetailsDesktop />
                  {/* <FeedBack /> */}
                </div>
              </div>
            )}
            {selectedTab == 'claimdetails' ? (
              <ShowClaimDetails />
            ) : selectedTab == 'documentdetails' ? (
              <ShowDocumentStatus />
            ) : selectedTab == 'bankdetails' ? (
              <ShowBankDetails />
            ) : selectedTab == 'claimletters' ? (
              <ShowClaimLetters />
            ) : selectedTab == 'actionrequired' ? (
              <ShowActionRequired
                actionRequiredDefaultOpen={actionRequiredDefaultOpen}
                setAlert={setAlert}
                clearAlert={clearAlert}
                status={claim_data.claim_status}
                documentsError={documentsError}
                update_progress={update_progress}
              />
            ) : (
              <></>
            )}

            {/* <ShowBankDetails/>
            <ShowClaimDetails/>
            <ShowDocumentStatus/>
            <ShowClaimLetters/> */}
          </div>
          <div></div>
          <Ratings
            claimnumber={claim_data.claim_id}
            policynumber={claim_data.policy_number}
            claimstatus={claim_data.claim_status}
          />
        </ClaimContext.Provider>

        {/* Show Alert warnings */}
        {alertStatus.status ? (
          <div>
            {!documentsError ? <div className="status_backlog"></div> : <></>}
            <AlertComponent alert_status={alertStatus} />
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  } else if (errStatus) {
    return (
      <div>
        <h6>Data not found</h6>
      </div>
    );
  }
  return (
    <div
      style={{
        height: '95vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {loading_status ? <Loader balls_color="grey" /> : 'Verify'}
      <span style={{ fontWeight: '500' }}>{loading_status}</span>
    </div>
  );
};

export default MainApp;
