const jwt = require('jsonwebtoken');

// const status_obj = {
//   'Under Review': 'Verification',
//   'Deny Request': 'Rejected',
//   'Claim Denied':'Rejected',
//   'Claim Repudiated': 'Rejected',
//   'Reimb Supplementry claim registered': 'Initiated',
//   'Reimbursement Supplementry claim registered': 'Initiated',
//   'Reimbursement claim registered': 'Initiated',
//   'Intimation Received': 'Initiated',
//   'Revisit Request by CEM Reviewer to Scan user':'Verification',
//   'Pending CEM Review': 'Verification',
//   'Pending CEM TL Review': 'Verification',
//   'Revisit Request by Reviewer to Scan user':'Verification',
//   'Query Request': 'Verification',
//   'Claim Approved': 'Approved',
//   'Claim Documents Received': 'Verification',
//   'Claim Disbursed': 'Disbursed',
//   'Claim Payment': 'Disbursed',
//   'Change Request By Medico': 'Verification',
//   'New Request': 'Initiated',
//   'Claims Doc Received': 'Verification',
//   'Payment Approve':'Approved'
// };

const status_obj = {
  'Buffer Request': 'Document Verification',
  'Buffer Request Approved': 'Document Verification',
  'Buffer Request Denied': 'Document Verification',
  'Un Assign New Voice Mail': 'Documents Verification',
  'Un Tagged Assign Voice Mails': 'Documents Verification',
  'Tagged Voice Mail': 'Documents Verification',
  'ReAssign Voice Mail': 'Documents Verification',
  'New Request - CC': 'Document Verification',
  'PreAuth-WIP': 'PreAuth Document Verification',
  'New Request - HS': 'Document Verification',
  'Change Request By Medico': 'Document Verification',
  'Expert Advice': 'Document Verification',
  'Query Request': 'Bill verification',
  'Investigation Request': 'Document Verification',
  'Approval Request': 'Bill verification',
  'Deny Request': 'Claim Rejected',
  'Pre Auth-WIP': 'PreAuth Documents Verification',
  'Change Request By Medico TL': 'Document Verification',
  'Pre Auth Approved': 'Pre- Authorization Approved',
  'Query Respond': 'Quality check',
  'Deny Respond': 'Quality check',
  'Approval Respond': 'Quality check',
  'Disagreement for Query': 'Quality check',
  'Revisit Request': 'Documents Verification',
  'Assign to Medico': 'Documents Verification',
  'Assign to Auditor': 'Quality check',
  'Enhancement Request - CC': 'Document Verification',
  'Enhancement Request WIP - CC': 'Document Verification',
  'Pre Auth - Query Reply': 'PreAuth Document Verification',
  'Invalid Claim': 'Claim Closed',
  'Pre Auth - Reminder Letter 1': 'PreAuth Additional documents request',
  'Pre Auth - Reminder Letter 2': 'PreAuth Additional documents request',
  'Pre Auth - Closure Letter': 'PreAuth Claim Closed',
  'Claim Re-Open': 'Bill verification',
  'Pre Auth - New Request - CRM': 'PreAuth Document Verification',
  'Pre Auth - Enhancement Request - CRM': 'PreAuth Document Verification',
  'Claim Close': 'Claim Closed',
  'Pre Auth - Enhancement Request - NSP': 'PreAuth Document Verification',
  'Pre Auth - New Request - NSP': 'PreAuth Document Verification',
  'Pre Auth - Final Reminder Letter': 'PreAuth Additional documents request',
  'Send Back Cases': 'Documents Verification',
  'Pre Auth - New Request - Draft': 'PreAuth Document Verification',
  'Pre Auth - Enhancement Request - Draft': 'PreAuth Documents Verification',
  'Group OPD Claims': 'Documents Verification',
  'Under Process': 'Document Verification',
  'New Request': 'Bill verification',
  'Payment Approve': 'Documents Verification',
  'Revisit Request by Auditor': 'Documents Verification',
  'Claims Doc Received': 'Document Verification',
  'Claim Approved': 'Claim Approved',
  'Claim Processing-WIP': 'Quality check',
  'Query Reply': 'Document Verification',
  'Claim Payment': 'Claim Paid',
  'Payment Document Downloaded': 'Claim Approved',
  'Cashless - Reminder Letter 1': 'Cashless Additional documents request',
  'Cashless - Reminder Letter 2': 'Cashless Additional documents request',
  'Cashless - Closure Letter': 'Cashless Claim Closed',
  'Claim Under Query': 'Quality check',
  'Supplementry Claim Request': 'Documents Verification',
  'Cashless - Final Reminder Letter': 'Cashless Additional documents request',
  'KYC Approval Request': 'Document Verification',
  'KYC Request Approved by CMO': 'Documents Verification',
  'KYC Revisit Request by CMO': 'Documents Verification',
  'Query raised for KYC': 'Quality check',
  'Query raised for NEFT': 'Quality check',
  'KYC or NEFT Query Response': 'Bill verification',
  'Under Review': 'Document Verification',
  'Reimb - Reminder Letter 1': 'Additional documents request',
  'Reimb - Reminder Letter 2': 'Additional documents request',
  'Reimb - Closure Letter': 'Claim Closed',
  'Investigation Reply Received': 'Document Verification',
  'Approved but not payable': 'Claim Approved',
  'Claim Denied': 'Claim Rejected',
  'Query Agreed': 'Bill verification',
  'NEFT Revisit Request': 'Quality check',
  'NEFT Response-Non Medcio': 'Bill verification',
  'Reimb - Final Reminder Letter': 'Additional documents request',
  'Process': 'Documents Verification',
  'Query Raised': 'Bill verification',
  'CardSure - Reminder Letter 1': 'Additional documents request',
  'CardSure - Reminder Letter 2': 'Additional documents request',
  'CardSure -Final Letter ': 'Additional documents request',
  'CardSure - Closure Letter': 'Claim Closed',
  'KYCApprovalRequest-AgreeAuditorTL': 'Document Verification',
  'Query raised for KYC and NEFT': 'Quality check',
  'Query Reply Re-Open': 'Document Verification',
  'Fraud Approval Request': 'Bill verification',
  'Fraud Request Approved by CMO': 'Pre- Authorization Repudiated',
  'Revisit Fraud Approval Request by CMO': 'Documents Verification',
  'Payment Send for Higher Approval': 'Quality check',
  'Reopen Request Approved by CMO': 'Documents Verification',
  'Revisit Request by Auditor to Scan user': 'Quality check',
  'Revisit Request by Medico to Scan user': 'Documents Verification',
  'Revisit Request by Medico TL to Scan user': 'Documents Verification',
  'Revisit Request by Non-Medico to Scan user': 'Document Verification',
  'Revisit Request by Non-Medico TL to Scan user': 'Bill verification',
  'Request from Non Medico': 'Bill verification',
  'Reserve Send for Higher Approval': 'Quality check',
  'Reserve Approve': 'Documents awaited',
  'Reserve Reject': 'Documents Verification',
  'Payment Reject': 'Quality check',
  'Send For Reserve Amount': 'Bill verification',
  'Reimb - Reminder Letter Final': 'Additional documents request',
  'Reimb - Doc Reminder Letter 1': 'Document awaited',
  'Reimb - Doc Reminder Letter 2': 'Document awaited',
  'FRAUD Revisit Request by CMO': 'Documents Verification',
  'Revisit Request by Scan User to Non-Medico': 'Documents Verification',
  'Revisit Request by Scan User to Medico': 'Documents Verification',
  'Revisit Request by Scan User to Auditor': 'Documents Verification',
  'Auto Query Request': 'Query Request',
  'Open For CRM Calling': 'Documents Verification',
  'CRM Follow Up': 'Documents Verification',
  'CRM No Answer': 'Documents Verification',
  'CRM Send For Correction': 'Documents Verification',
  'CRM DC Assigned': 'Documents Verification',
  'DC No Answer': 'Documents Verification',
  'DC Appointment Fixed': 'Documents Verification',
  'DC Appointment Rescheduled': 'Documents Verification',
  'DC Follow Up': 'Documents Verification',
  'Reports Uploaded By DC - WIP': 'Documents Verification',
  'Reports Uploaded By DC - Test Completed': 'Documents Verification',
  'Reports Uploaded By DC - Test InComplete': 'Documents Verification',
  'Case Assigned to UW': 'Cashless Documents Verification',
  'Case Assigned to DC': 'Cashless Documents Verification',
  'UW Accepted': 'Documents Verification',
  'UW Not Accepted': 'Documents Verification',
  'UW Accepted With Risk': 'Documents Verification',
  'Additional Information Required By UW': 'Documents Verification',
  'Contact Details Updated By Sales User': 'Documents Verification',
  'Close': 'Documents Verification',
  'No Answer For 3 Times': 'Documents Verification',
  'No Answer By Customer': 'Documents Verification',
  'No Show by CRM': 'Documents Verification',
  'No Show by DC': 'Documents Verification',
  'Additional Information Updated By Sales User': 'Documents Verification',
  'JFM CASE': 'Documents Verification',
  'CLOSE PARENT PROPOSAL': 'Documents Verification',
  'PENDING FOR COUNTER OFFER': 'Documents Verification',
  'COUNTER OFFER RECEIVED': 'Documents Verification',
  'OPS ISSUED': 'Documents Verification',
  'CLOSED': 'Documents Verification',
  'NO ACTION': 'Documents Verification',
  'NO MATRIX DEFINED': 'Documents Verification',
  'Close by CRM': 'Documents Verification',
  'Additional Information Required From CRM': 'Documents Verification',
  'Additional Information Updated By CRM': 'Documents Verification',
  'Cancel': 'Documents Verification',
  'Reopen': 'Documents Verification',
  'JFM APPROVED': 'Documents Verification',
  'DUPLICATE LEAD': 'Documents Verification',
  'Send for payment approval': 'Documents Verification',
  'DC payment approved': 'Documents Verification',
  'DC payment rejected': 'Documents Verification',
  'Payment Error': 'Documents Verification',
  'Payment Done': 'Documents Verification',
  'UW CLOSE': 'Documents Verification',
  'DC Appointment Reminder': 'Documents Verification',
  'Pending scaning from I3': 'Documents Verification',
  'Completed scaning from I3': 'Documents Verification',
  'Incomplete Document': 'Documents Verification',
  'Missing Report': 'Documents Verification',
  'Missing Report Data': 'Documents Verification',
  'Failed scanning from i3': 'Documents Verification',
  'Pending Payment From PIE': 'Documents Verification',
  'Self Test': 'Documents Verification',
  'Dual Proposal': 'Documents Verification',
  'Park for HealthX': 'Documents Verification',
  'Archive Transaction': 'Documents Verification',
  'PPC_AUTO_APPROVED': 'Documents Verification',
  'Auto Approved By Rule Engine': 'Documents Verification',
  'Auto Rejected By Rule Engine': 'Documents Verification',
  'PPC TELE MER': 'Documents Verification',
  'Renewal Customer': 'Documents Verification',
  'Non Contactable': 'Documents Verification',
  'Closed Refunded': 'Documents Verification',
  'Duplicate Proposal': 'Documents Verification',
  'AUTO_APPROVED_TVC_STP': 'Documents Verification',
  'Barcode Untagged Documents': 'Not valid status',
  'Additional Info Required': 'Not valid status',
  'Send Back From CRM USR': 'Not valid status',
  'Send Back From NM TL': 'Not valid status',
  'Claim Intimated': 'Not valid status',
  'Send Back From EMP User': 'Not valid status',
  'Case Rejected': 'Not valid status',
  'Deleted Untagged Barcode Documents': 'Document awaited',
  'Top-up Request': 'Documents Verification',
  'Top-up Approval Request': 'Documents Verification',
  'Top-up Deny Request': 'Documents Verification',
  'Top-up Approved': 'Documents Verification',
  'Top-up Denied': 'Quality check',
  'Top-up Revisit Request': 'Quality check',
  'Invalid Topup Claim': 'Claim Closed',
  'Topup Claim Close': 'Claim Closed',
  'Top-up Claim Reopen': 'Documents Verification',
  'Payment Process Initiated-Pending for Send to Approval':
    'Documents Verification',
  'Payment Sent for Approval request': 'Documents Verification',
  'Payment Request Approved': 'Documents Verification',
  'Payment Request Rejected': 'Documents Verification',
  'Payment Processed': 'Documents Verification',
  'Payment Confirmed': 'Documents Verification',
  'Payment Cancelled': 'Documents Verification',
  'Payment Stop': 'Documents Verification',
  'Payment Reissued': 'Documents Verification',
  'Payment Hold': 'Documents Verification',
  'Payment UnHold': 'Documents Verification',
  'Payment Sent For Oracle Finance Processing': 'Documents Verification',
  'Payment Failed': 'Documents Verification',
  'Investigation Raised By Medico': 'Under external verification',
  'Investigation Approved': 'Under external verification',
  'Investigation Report Received': 'Under external verification',
  'Investigation Denyed by MEDICO TL': 'Under external verification',
  'Pending PPC Flow for UW Decision': 'Documents Verification',
  'Additional Information Required for UW Decision': 'Documents Verification',
  'PPC Flow Rejected By UW': 'Documents Verification',
  'Rejected Due To Dependent PPC Rejected': 'Documents Verification',
  'In Hold Till Dependent PPC Approve By UW': 'Documents Verification',
  'In Hold Till Dependent PPC PED Approve By UW': 'Documents Verification',
  'PPC Accepted By UW': 'Documents Verification',
  'Accepted With Condition': 'Documents Verification',
  'Pending Due To Dependent on Add Info Req': 'Documents Verification',
  'Cancelled Due To Auto Refund': 'Documents Verification',
  'Payment Recovery Confirmed': 'Documents Verification',
  'Payment Recovery Cancelled': 'Documents Verification',
  'Investigation Raised By Call Center': 'Under external verification',
  'Proposal Park for UW Review': 'Documents Verification',
  'Proposal Closed with Parent Proposal': 'Documents Verification',
  'Proposal Open': 'Documents Verification',
  'Proposal Accepted': 'Documents Verification',
  'Proposal Accepted With Risk': 'Documents Verification',
  'Proposal Rejected': 'Documents Verification',
  'Proposal Closed': 'Documents Verification',
  'Proposal Counter Offer': 'Documents Verification',
  'PPC in Progress': 'Documents Verification',
  'Proposal Additional Information Required': 'Documents Verification',
  'Proposal Pending for UW Decision': 'Documents Verification',
  'Proposal JFM Approved': 'Documents Verification',
  'Proposal NO MATRIX DEFINED': 'Documents Verification',
  'Proposal Additional Information Updated': 'Documents Verification',
  'Proposal Case Assigned to UW': 'Documents Verification',
  'Proposal Duplicate Lead': 'Documents Verification',
  'Proposal Completed scaning from I3': 'Documents Verification',
  'Proposal Pending Payment From PIE': 'Documents Verification',
  'Proposal Completed Scaning': 'Documents Verification',
  'Proposal Completed Without Scaning': 'Documents Verification',
  'Proposal Verification pending at Non Medico': 'Documents Verification',
  'Proposal No Show': 'Documents Verification',
  'Proposal send back to Non Medico': 'Documents Verification',
  'Proposal Park for HealthX': 'Documents Verification',
  'Dual Proposal Accepted With Risk': 'Documents Verification',
  'Investigation Raised By Enrollment User': 'Under external verification',
  'Investigation Raised By NSP Portal': 'Under external verification',
  'Investigation Raised By IPO': 'Under external verification',
  'TPA Loading Updated': 'Documents Verification',
  'Proposal PPC Auto Approved': 'Documents Verification',
  'PPC Proposal Auto Approved': 'Documents Verification',
  'Proposal Auto Approved By Rule Engine': 'Documents Verification',
  'Proposal Auto Rejected By Rule Engine': 'Documents Verification',
  'Proposal Verification Pending at I3 By UW': 'Documents Verification',
  'Proposal Sent For Tele MER': 'Documents Verification',
  'Proposal Received After Tele MER': 'Documents Verification',
  'Accept': 'Documents Verification',
  'Not Accept': 'Documents Verification',
  'No Show': 'Documents Verification',
  'Payment Pending': 'Documents Verification',
  'Pre Auth  - Send Back to NSP': 'PreAuth Documents Verification',
  'Recovery Intimated': 'Documents Verification',
  'Open For Health Coupon': 'Documents Verification',
  'CRM DC Assigned hc': 'Documents Verification',
  'DC Appointment Fixed hc': 'Documents Verification',
  'Report Uploaded - Test Completed HC': 'Documents Verification',
  'Report Uploaded - Test Incomplete HC': 'Documents Verification',
  'Send for payment approval HC': 'Documents Verification',
  'DC payment approved HC': 'Documents Verification',
  'DC payment rejected HC': 'Documents Verification',
  'Claim Under Query With CRM': 'Document Verification',
  'Claim Mark as Close by CRM': 'Document Verification',
  'Pending': 'Not valid status',
  'Follow UP': 'Not valid status',
  'Contacted': 'OUTCALLING',
  'Non contacted': 'Not valid status',
  'Auto Close': 'Close',
  'Contact-Not available': 'OUTCALLING',
  'Missed Call - Registered Number': 'Not valid status',
  'Missed Call - Non Registered Number': 'Not valid status',
  'Missed Call - Non Registered Number Update': 'Not valid status',
  'Investigation Raised By CC for Block HS': 'Under external verification',
  'TV PENDING FOR MER': 'Documents Verification',
  'TUW PENDING FOR MER': 'Documents Verification',
  'PENDING FOR TV': 'Documents Verification',
  'PENDING FOR TUW': 'Documents Verification',
  'Accepted': 'Documents Verification',
  'Rejected': 'Documents Verification',
  'AIU PENDING FOR MER': 'Documents Verification',
  'PPC PENDING FOR MER': 'Documents Verification',
  'Proposal Sent For Tele VER': 'Documents Verification',
  'MOVE TO RMS': 'Documents Verification',
  'BRE CASE': 'Documents Verification',
  'Additional Information for Medical': 'Documents Verification',
  'Proposal Pending for Document': 'Documents Verification',
  'Proposal Pending for Portability': 'Documents Verification',
  'Temporary Proposal Rejection': 'Documents Verification',
  'Investigation Raised By NSP Portal Fraud Agent':
    'Under external verification',
  'Investigation Raised By IPO Fraud Agent': 'Under external verification',
  'Investigation Raised By Call Center Fraud Agent':
    'Under external verification',
  'Proposal Pending for MER': 'Documents Verification',
  'Reimb - Reminder Letter 3': 'Additional documents request',
  'Reimb - Reminder Letter 4': 'Additional documents request',
  'Reimb - Reminder Letter 5': 'Additional documents request',
  'Reimb - Reminder Letter 6': 'Additional documents request',
  'Cashless - Reminder Letter 3': 'Cashless Additional documents request',
  'Cashless - Reminder Letter 4': 'Cashless Additional documents request',
  'Cashless - Reminder Letter 5': 'Cashless Additional documents request',
  'Cashless - Reminder Letter 6': 'Cashless Additional documents request',
  'Payment Partial Recovery Confirmed': 'Documents Verification',
  'PPC Flow Rejected By PPC': 'Documents Verification',
  'Auto Cancel by Source': 'Close',
  'Sent to CMO': 'Pre- Authorization Repudiated',
  'Pre Auth - Claims Doc Received - CRM': 'PreAuth Document Verification',
  'Pre Auth - Enhancement Claims Doc Received - CRM': 'PreAuth Document Verification',
  'Pre Auth - Under Process': 'PreAuth Bill verification',
  'Pre-Auth - Send Back to Non Medico - CRM': 'PreAuth Documents Verification',
  'Revisit Request by Reviewer to Scan user': 'Document Verification',
  'Revisit Request by Reviewer': 'Claim Rejected',
  'Deny Agreed': 'Claim Rejected',
  'Revisit Request by CEM Reviewer':'Documents Verification',
  'Revisit Request by CEM Reviewer to Scan user':'Documents Verification',
  'Pending CEM TL Review':'Documents Verification',
  'Pending CEM Review':'Documents Verification'
};

const checkAuthorization = () => {
  let token = window.sessionStorage.getItem('_ssid');
  // console.log('token: ', token);
  if (token) {
    // console.log('token: ', token);
    return token;
  }
  return null;
};

// IndexDB utility

const storeDataInIndexDb = async () => {
  // adding in index db
  const save_data = await window.sessionStorage.getItem('claims_data');
  // console.log(save_data);
  async function add_in_db(claimsList) {
    await window.sessionStorage.setItem(
      'claims_data',
      JSON.stringify(claimsList)
    );
  }

  // checking in index db
  function check_in_db() {
    if (save_data) {
      return { status: 'successful', data: JSON.parse(save_data) };
    }
    return { status: null, data: [] };
  }

  return {
    add: add_in_db,
    check: check_in_db,
  };
};

const dateSorting = input => {
  let start_point = 0;
  let pivot_point = input.length - 1;
  while (start_point <= pivot_point) {
    // console.log('start_point: ', start_point);
    let pivot = input[pivot_point]['claim_data']['claim_intimation'].split('/');
    let start = input[start_point]['claim_data']['claim_intimation'].split('/');
    if (start_point === pivot_point) {
      pivot_point = pivot_point - 1;
      start_point = 0;
      continue;
    }

    if (parseInt(pivot[2]) > parseInt(start[2])) {
      // replace pivot with start
      let start = input[start_point];
      input[start_point] = input[pivot_point];
      input[pivot_point] = start;
      start_point = 0;
      continue;
    } else if (parseInt(pivot[2]) < parseInt(start[2])) {
      // check for next small date
      start_point++;
      continue;
    }
    // break;
    if (parseInt(pivot[2]) === parseInt(start[2])) {
      // check for greater month
      if (parseInt(pivot[1]) > parseInt(start[1])) {
        // replace pivot with start
        let start = input[start_point];
        input[start_point] = input[pivot_point];
        input[pivot_point] = start;
        start_point = 0;
        continue;
      } else if (parseInt(pivot[1]) < parseInt(start[1])) {
        // check for next small date
        start_point++;
        continue;
      } else if (parseInt(pivot[1]) === parseInt(start[1])) {
        // check for greater date
        if (parseInt(pivot[0]) > parseInt(start[0])) {
          // replace pivot with start
          let start = input[start_point];
          input[start_point] = input[pivot_point];
          input[pivot_point] = start;
          start_point = 0;
        } else if (parseInt(pivot[0]) < parseInt(start[0])) {
          // check for next small date
          start_point++;
          continue;
        } else if (parseInt(pivot[0]) === parseInt(start[0])) {
          start_point++;
        }
      }
    }
  }
  return input;
};

// const updates_accordingto_intimation = date => {
//   console.log('Updates', date);
//   let current_date = new Date();
//   let year = current_date.getFullYear();
//   let month = current_date.getMonth() +1;
//   let day = current_date.getDate();
//   if (date) {
//     let received_date = date.split('/');
//     if (parseInt(received_date[2]) === year) {
//       if (parseInt(received_date[1]) === month) {
//         // Same date as calim initimated
//         if (parseInt(received_date[0]) === day) {
//           return 'We will update you in 2 days';
//         }
//         if (received_date[0] - day <= -2) {
//           return 'It is taking longer than expected time, will get back to you';
//         }
//       }
//       return 'Sorry for delay we will notify you as soon as possible';
//     }
//     return 'Old claim';
//   }
// };
//============ function by na==========
const updates_accordingto_intimation = status => {
  if (status == 'Documents Verification' || status == 'Document Verification' || status == 'Additional documents request' || status == 'Quality check') {
    return 'Claim Under Process';
  }
  if (status == 'Claim Approved') {
    return 'Claim Approved';
  }
  if (status == 'Claim Paid'||status == 'Claim Closed'||status == 'Close') {
    return 'Claim Approved and Disbursed';
  }
  if (status == 'Claim Rejected') {
    return 'Claim Rejected';
  }
  return status;
};
// ========================
// const status_mapping = (status) => {
//   return status_obj[status]
// }

// const enc = data => {
//   const result = jwt.sign(data, 'Advisory');
//   return result;
// };

const returnSingleName = (name) =>{
  if(name){
  let newName = name.split(" ");
  let str = "";
  let [...newName1] = new Set(newName);
  let singleName = newName1.join(" ")
  // console.log("nnnnn",singleName );
  return singleName
  // console.log("nnnnn", newName1);
  // str = `${str}${newName1[0]||""}` 
  // str = `${str} ${newName1[1]||""}` 
  // str = `${str} ${newName1[2]||""}` 
  // str = `${str} ${newName1[3]||""}` 
  // str = `${str} ${newName1[4]||""}`
  // return str;
  }
}

const indianCurrencyFormat =(rs) =>{
  rs = Math.floor(rs)
  let x=rs.toString();
  let lastThree = x.substring(x.length-3);
  let otherNumbers = x.substring(0,x.length-3);
  if(otherNumbers != '')
      lastThree = ',' + lastThree;
  let res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
  return res;
}



export {
  checkAuthorization,
  storeDataInIndexDb,
  dateSorting,
  updates_accordingto_intimation,
  returnSingleName,
  indianCurrencyFormat,
  // status_mapping,
  status_obj,
};
