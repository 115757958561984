import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ClaimContext } from '../../context/ClaimContext';
import './Mainapp.css';
import Story from '../Stories/Stories';
import CardGreen from '../CardGreen/CardGreen';
import CardYellow from '../CardYellow/CardYellow';
import CardRed from '../CardRed/CardRed';
import BankDetails from '../BankDetails/BankDetails';
import BankDetailsDesktop from '../BankDetails/BankDetailsDesktop';
import { ShowBankDetails } from '../BankDetails/BankDetailsDesktop';
import ClaimDetailsDesktop from '../ClaimDetails/ClaimDetailsDesktop';
import { ShowClaimDetails } from '../ClaimDetails/ClaimDetailsDesktop';
import DocumentStatusDesktop from '../DocumentStatus/DocumentStatusDesktop1';
import { ShowDocumentStatus } from '../DocumentStatus/DocumentStatusDesktop1';
import ActionRequiredDesktop from '../ActionRequired/ActionRequiredDesktop1';
import { ShowActionRequired } from '../ActionRequired/ActionRequiredDesktop1';
import ClaimLettersDesktop from '../ClaimLetter/ClaimLettersDesktop1';
import { ShowClaimLetters } from '../ClaimLetter/ClaimLettersDesktop1';

import InitiateCard from '../InitiateCard/InitiateCard';
import ClaimStatusTracker from '../ClaimStatusTracker/ClaimStatusTracker';
import ClaimDetails from '../ClaimDetails/ClaimDetails';
import DocumentStatus from '../DocumentStatus/DocumentStatus1';
import ActionRequired from '../ActionRequired/ActionRequired1';
import ClaimAmount from '../ClaimAmount/ClaimAmount';
import ClaimLetter from '../ClaimLetter/ClaimLetters1';
import { AlertComponent } from '../Alert/Alert';
import FeedBack from '../FeedBack/FeedBack';
import ServerDown from '../../Assets/server-down.png';
import 'react-loading-skeleton/dist/skeleton.css';
import { env } from '../../config/env';
import claimsData from './claims.json';

import Loader from '../Loader/Loader1';
import NotificationAction from '../NotificationAction/NotificationAction';
import Header from '../Header/Header';
import { dateSorting, status_obj } from '../../utility';
import Ratings from '../Ratings/Ratings1';
import ReactGA from 'react-ga';

const status_obj1 = {
  'Under Process': 'Verification',
  'Deny Request': 'Rejected',
  'Claim Repudiated': 'Rejected',
  'Claim Payment': 'Disbursed',
  'Reimb Supplementry claim registered': 'Initiated',
  'Reimbursement Supplementry claim registered': 'Initiated',
  'Reimbursement claim registered': 'Initiated',
  'Intimation Received': 'Initiated',
  'Query Request': 'Verification',
  'Claim Approved': 'Approved',
  'Claim Documents Received': 'Verification',
  'Claims Doc Received': 'Verification',
  'Claim Disbursed': 'Disbursed',
  'Supplementry Claim Request': 'Initiated',
  'New Request': 'Initiated',
  'Change Request By Medico': 'Verification',
  'Additional documents request':'Verification',

};

const claims_categories = {
  Verification: 'In Progress Claims',
  Initiated: 'In Progress Claims',
  Rejected: 'Rejected Claims',
  Approved: 'Approved Claims',
  'Approved & Disbursed': 'Approved Claims',
  'Additional documents request': 'In Progress Claims',
  'Bill verification': 'In Progress Claims',
  'Claim Approved': 'Approved Claims',
  'Claim Closed': 'Approved Claims',
  'Claim Intimated': 'In Progress Claims',
  'Claim Paid': 'Approved Claims',
  'Claim Rejected': 'Rejected Claims',
  Close: 'Approved Claims',
  'Document awaited': 'In Progress Claims',
  'Document Verification': 'In Progress Claims',
  'Documents awaited': 'In Progress Claims',
  'Documents Verification': 'In Progress Claims',
  'Not valid status': 'In Progress Claims',
  OUTCALLING: 'In Progress Claims',
  'Pre- Authorization Approved': 'Approved Claims',
  'Pre- Authorization Repudiated': 'Rejected Claims',
  'Quality check': 'In Progress Claims',
  'Query Request': 'In Progress Claims',
  'Under external verification': 'In Progress Claims',
  'Additional documents request':'In Progress Claims',

};

const MainApp1 = ({
  setClaims,
  current_claim,
  updateIntimationStatus,
  setClaimsList,
  existedClaimsData,
}) => {
  // window.scrollTo({ top:0, left: 0 });
  // window.sessionStorage.setItem('claim', JSON.stringify(claimsData.data));
  // window.sessionStorage.setItem('claims_data', JSON.stringify(claimsData.data));

  const { encclaimNumber, encpolicyNumber } = useParams();
  const navigate = useNavigate();

  // console.log('encpc', encclaimNumber, encpolicyNumber);

  const context = useContext(ClaimContext);
  // state
  const [selectedTab, setSelectedTab] = useState('claimdetails');

  const [documents, setDocuments] = useState(null);
  const [documentsError, setDocumentsError] = useState(false);
  const [loading_status, setLoadingStatus] = useState(false);
  const [error, setError] = useState(false);
  const [unauthorized, setUnauthorized] = useState(false);
  const [actionRequiredDefaultOpen, setActionRequiredDefaultOpen] =
    useState(false);

  const [errStatus, setErrStatus] = useState(false);
  const [alertStatus, setAlertStatus] = useState({
    status: false,
    msg: null,
    warning_status: '',
  });

  // const setClaimData = (claim) => {
  //   console.log('claim in set claim', claim)
  //   setClaims(claim, () => null);
  // };

  // apicalling same as claims start
  // distrubed claim
  // const distribute_claims = claims => {
  //   console.log('calimin dc', claims);
  //   let claims_container = {
  //     'In Progress Claims': [],
  //     'Rejected Claims': [],
  //     'Approved': [],
  //     'Others': [],
  //   };

  //       if (claims) {
  //         for (let i = 0; i < claims.length; i++) {
  //           const claim = claims[i];
  //           console.log("for loop claim",claim)

  //       // check if respective claim type exist or not
  //       claim['claim_data']["claim_status"] = status_obj[claim['claim_data']['external_status']]

  //       if (
  //         claims_container[
  //           claims_categories[
  //             status_obj[claim['claim_data']['external_status']]
  //           ]
  //         ]
  //       ) {
  //         // console.log("container",claims_container[
  //         //   claims_categories[
  //         //     status_obj[claim['claim_data']['external_status']]
  //         //   ]
  //         // ])
  //         claims_container[
  //           claims_categories[
  //             status_obj[claim['claim_data']['external_status']]
  //           ]
  //         ].push(claim);
  //       } else {
  //         claims_container['Approved'].push(claim);
  //       }
  //     }
  //     // );
  //     //  setClaimsList(mapedarray)
  //   }
  //   console.log('claims_container before setclaimlist', claims_container);
  //    setClaimsList(claims_container);
  //   //  return claims_container;
  // };

  // distrubed claim end

  // distrubuted claim new way start

  const distribute_claims = claims => {
    let { bank_data, claim_data, document_data } = claims;
    ReactGA.event({
      category: 'whatsapp_login',
      action: 'whatsapp_login',
      label: 'whatsapp_login'
    });

    let claims_container = {};
    if (claim_data) {
      claim_data['claim_status'] = status_obj[claim_data['external_status']];
      // console.log("trur.false",claim_data["claim_status"] = status_obj[claim_data['external_status']])
      if (
        claims_container[
          claims_categories[status_obj[claim_data['external_status']]]
        ]
      ) {
        claims_container[
          claims_categories[status_obj[claim_data['external_status']]]
        ].push(claim_data);
      }
    }
    //     console.log("claims_container", claims_container)
    // let newclaims_container = {bank_data,claim_data:claims_container, document_data}
    // console.log('newclaims_container', newclaims_container)
    // setClaims(newclaims_container, () => null);
    // console.log('claims_container before setclaimlist', claims_container);
  };

  //  setClaimsList(claims_container);
  //  return claims_container;
  // };
  // distrubuted claim new way end

  //fetch data
  const fetchClaimData = () => {
    if (!existedClaimsData) {
      let existed_data = JSON.parse(
        window.sessionStorage.getItem('claims_data')
      );
      async function fetchData() {
        try {
          setLoadingStatus(true);
          const res = await fetch(
            `${env.SERVER_URL}/get_claims/get_single_claim_details/${encclaimNumber}/${encpolicyNumber}`
          );
          const final_response = await res.json();
          // const final_response = claimsData;
          // console.log('final_response', final_response);

          if (final_response.status && final_response.data.length !== 0) {
            distribute_claims(final_response.data);
          }
          setClaims(final_response.data, () => null);
          setLoadingStatus(false);
        } catch (error) {
          // console.log(error);
          setError(true);
          setLoadingStatus(false);
          // }
        }
        if (existed_data) {
          let data = existed_data;
          if (data.length !== 0) {
            setClaimsList(data);
            return;
          }
        }
      }
      fetchData();
    }
  };

  // apicalling same as claims end

  useEffect(() => {
    window.scroll({
      top: -100,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  const clearAlert = () => {
    setAlertStatus({ status: false, msg: null, warning_status: '' });
    document.getElementsByTagName('body')[0].style.height = 'auto';
    document.getElementsByTagName('body')[0].style.overflow = 'scroll';
  };
  const setAlert = alert => {
    setAlertStatus(alert);
    document.getElementsByTagName('body')[0].style.height = '100vh';
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
  };

  const update_progress = progress => {
    if (progress > 0) {
      document.getElementsByClassName('alert_progress')[0].innerHTML = `${
        progress.toString().split('.')[0]
      }%`;
    } else {
      document.getElementsByClassName('alert_progress')[0].innerHTML = ``;
    }
  };

  useEffect(() => {
    // console.log('useeffect called');
    // let cached_claim = window.sessionStorage.getItem('claim');
    // if (!cached_claim) {
    //   return navigate('/claimList');
    // }
    // if (cached_claim) {
    //   setClaims(JSON.parse(cached_claim), () => null);
    // }
    fetchClaimData();
  }, []);


  useEffect(() => {
    if(actionRequiredDefaultOpen){
      setSelectedTab('actionrequired')
    }
   
  }, [actionRequiredDefaultOpen]);





  //   // same as claims
  //   const distribute_claims = claims => {
  //     let claims_container = {
  //       'In Progress Claims': [],
  //       'Rejected Claims': [],
  //       'Approved': [],
  //       'Others': [],
  //     };
  //     let sorted_claims = dateSorting(claims);
  //     if (sorted_claims) {
  //       sorted_claims.forEach(claim => {
  //         // check if respective claim type exist or not
  //         claim['claim_data']["claim_status"] = status_obj[claim['claim_data']['external_status']]
  //         if (
  //           claims_container[
  //             claims_categories[
  //               status_obj[claim['claim_data']['external_status']]
  //             ]
  //           ]
  //         ) {
  //           // console.log(claims_container[
  //           //   claims_categories[
  //           //     status_obj[claim['claim_data']['external_status']]
  //           //   ]
  //           // ])
  //           claims_container[
  //             claims_categories[
  //               status_obj[claim['claim_data']['external_status']]
  //             ]
  //           ].push(claim);
  //         }
  //          else {
  //           claims_container['Approved'].push(claim);
  //         }
  //       });
  //     }
  //     setClaimsList(claims_container);
  //   };
  //  // same as claims

  if (error) {
    return (
      <div className="server-down">
        <img src={ServerDown} alt="" />
      </div>
    );
  }

  if (unauthorized) {
    return (
      <div className="server-down">
        <img src={ServerDown} alt="" />
        <p>Your session is expired. Please Login again</p>
      </div>
    );
  }
  if (current_claim) {
    // console.log('current claim', current_claim);
    let { claim_data, bank_data, document_data } = current_claim;
    return (
      <div className="main_cx_container">
        <ClaimContext.Provider
          value={{
            data: claim_data,
            updateIntimationStatus,
            documents: document_data,
            bank_data,
          }}
        >
          <Header
            name={claim_data.proposer_name}
            fineText="Hope you are doing fine"
          />
          <div className="mainAppContainer">
            <Story />
            <div className="mainAppContainerCard">
              {claim_data && (claim_data.claim_status === 'Claim Rejected' || 
                claim_data.external_status === 'Reimb - Closure Letter'
              ) ? (
                <CardRed />
              ) : (claim_data &&
                  claim_data.claim_status === 'Claim Approved') ||
                (claim_data && claim_data.claim_status === 'Claim Paid') ||
                (claim_data && claim_data.claim_status === 'Claim Closed') ||
                (claim_data && claim_data.claim_status === 'Close') ? (
                <CardGreen />
              ) : (claim_data &&
                  claim_data.claim_status === 'Bill verification') ||
                (claim_data &&
                  claim_data.claim_status === 'Document Verification') ||
                (claim_data &&
                  claim_data.claim_status === 'Documents Verification') ||
                (claim_data &&
                  claim_data.claim_status === 'Additional documents request') ||
                (claim_data &&
                  claim_data.claim_status === 'Document awaited') ||
                (claim_data && claim_data.claim_status === 'Quality check') ? (
                <CardYellow />
              ) : (
                <InitiateCard />
              )}
            </div>
          </div>

          <NotificationAction
            setActionRequiredDefaultOpen={setActionRequiredDefaultOpen}
          />
          <div className="ratingPlusTracker">
            {/* <Ratings claimnumber={encclaimNumber} policynumber={encpolicyNumber}/> */}
            <div></div>
            {/* <div className='ClaimStatusInformation'>
          <p className='ClaimStatusInformationP'>Claims Status <span className='blink_me'>Updated</span></p>
          <p className='ClaimStatusInformationP1'>Document Verification - Sample Text : HDFC ERGO will scrutinize all documents and approve the claim. In case additional information or documents are required, HDFC ERGO will call for the same and upon satisfactory receipt of all necessary documents the claim will be settled by HDFC ERGO</p>
         </div> */}

            {claim_data ? (
              <ClaimStatusTracker statu={claim_data.claim_status} />
            ) : (
              <div></div>
            )}
            <div></div>
          </div>

          {/* {claim_data ? (
            <ClaimStatusTracker statu={claim_data.claim_status} />
          ) : (
            <div></div>
          )} */}

          <div className="claimDetailsForMobile">
            <div className="mainAppDetails">
              <div className="mainAppDetailsSection">
                <ClaimDetails />
              </div>
              {claim_data && claim_data.claim_status ? (
                <div className="mainAppDetailsSection">
                  {(claim_data.claim_type=="PREAUTH"||claim_data.claim_type=="Cashless" ||claim_data.claim_type=="CASHLESS"||claim_data.claim_type=='PRE-AUTH DISCHARGE')?
                  <></>:<DocumentStatus
                    setAlert={setAlert}
                    clearAlert={clearAlert}
                    status={claim_data.claim_status}
                    documentsError={documentsError}
                    update_progress={update_progress}
                  />}
                </div>
              ) : (
                <></>
              )}
              <div className="mainAppDetailsSection">
              {(claim_data.claim_type=="PREAUTH"||claim_data.claim_type=="Cashless" ||claim_data.claim_type=="CASHLESS"||claim_data.claim_type=='PRE-AUTH DISCHARGE')?
                  <></>:
                <ActionRequired
                  actionRequiredDefaultOpen={actionRequiredDefaultOpen}
                  setAlert={setAlert}
                  clearAlert={clearAlert}
                  status={claim_data.claim_status}
                  documentsError={documentsError}
                  update_progress={update_progress}
                />}
              </div>
              {/* Letters for claims if available */}
              <div className="mainAppDetailsSection">
              {(claim_data.claim_type=="PREAUTH"||claim_data.claim_type=="Cashless" ||claim_data.claim_type=="CASHLESS"||claim_data.claim_type=='PRE-AUTH DISCHARGE')?
                  <></>:<ClaimLetter
                  setAlert={setAlert}
                  clearAlert={clearAlert}
                  status={claim_data.claim_status}
                  documentsError={documentsError}
                  update_progress={update_progress}
                />}
              </div>
              {/* <DocumentStatus /> */}
              {/* <ClaimAmount /> */}
              <div className="mainAppDetailsSection">
              {(claim_data.claim_type=="PREAUTH"||claim_data.claim_type=="Cashless" ||claim_data.claim_type=="CASHLESS"||claim_data.claim_type=='PRE-AUTH DISCHARGE')?
                  <></>:
                <>{bank_data ? <BankDetails /> : <></>}</>
              }
              </div>
            </div>
          </div>
          <div className="claimDetailsForDesktop">
            <div className="mainAppDetails">
              <div
                className={`mainAppDetailsSection ${
                  selectedTab == 'claimdetails' ? 'selected-tab-color' : ''
                }`}
                onClick={() => {
                  setSelectedTab('claimdetails');
                }}
              >{(claim_data.claim_type=="PREAUTH"||claim_data.claim_type=="Cashless" ||claim_data.claim_type=="CASHLESS"||claim_data.claim_type=='PRE-AUTH DISCHARGE')?
              <></>:
                <ClaimDetailsDesktop />}
              </div>
              {claim_data && claim_data.claim_status ? (
                <div
                  className={`mainAppDetailsSection ${
                    selectedTab == 'documentdetails' ? 'selected-tab-color' : ''
                  }`}
                  onClick={() => {
                    setSelectedTab('documentdetails');
                  }}
                >
                  {(claim_data.claim_type=="PREAUTH"||claim_data.claim_type=="Cashless" ||claim_data.claim_type=="CASHLESS"||claim_data.claim_type=='PRE-AUTH DISCHARGE')?
                  <></>:
                  <DocumentStatusDesktop
                    setAlert={setAlert}
                    clearAlert={clearAlert}
                    status={claim_data.claim_status}
                    documentsError={documentsError}
                    update_progress={update_progress}
                  />}
                </div>
              ) : (
                <></>
              )}
              <div
                className={`mainAppDetailsSection ${
                  selectedTab == 'actionrequired' ? 'selected-tab-color' : ''
                }`}
                onClick={() => {
                  setSelectedTab('actionrequired');
                }}
              >
                {(claim_data.claim_type=="PREAUTH"||claim_data.claim_type=="Cashless" ||claim_data.claim_type=="CASHLESS" ||claim_data.claim_type=='PRE-AUTH DISCHARGE')?
                  <></>:
                <ActionRequiredDesktop
                  actionRequiredDefaultOpen={actionRequiredDefaultOpen}
                  setAlert={setAlert}
                  clearAlert={clearAlert}
                  status={claim_data.claim_status}
                  documentsError={documentsError}
                  update_progress={update_progress}
                />}
              </div>
              {/* Letters for claims if available */}
              <div
                className={`mainAppDetailsSection ${
                  selectedTab == 'claimletters' ? 'selected-tab-color' : ''
                }`}
                onClick={() => {
                  setSelectedTab('claimletters');
                }}
              >
               {(claim_data.claim_type=="PREAUTH"||claim_data.claim_type=="Cashless" ||claim_data.claim_type=="CASHLESS"||claim_data.claim_type=='PRE-AUTH DISCHARGE')?
                  <></>: <ClaimLettersDesktop
                  setAlert={setAlert}
                  clearAlert={clearAlert}
                  status={claim_data.claim_status}
                  documentsError={documentsError}
                  update_progress={update_progress}
                />}
              </div>
              {/* <DocumentStatus /> */}
              {/* <ClaimAmount /> */}
              <div
                className={`mainAppDetailsSection ${
                  selectedTab == 'bankdetails' ? 'selected-tab-color' : ''
                }`}
                onClick={() => {
                  setSelectedTab('bankdetails');
                }}
              >{(claim_data.claim_type=="PREAUTH"||claim_data.claim_type=="Cashless" ||claim_data.claim_type=="CASHLESS"||claim_data.claim_type=='PRE-AUTH DISCHARGE')?
              <></>:
                <BankDetailsDesktop />}
                {/* <FeedBack /> */}
              </div>
            </div>
            {selectedTab == 'claimdetails' ? (
              <ShowClaimDetails />
            ) : selectedTab == 'documentdetails' ? (
              <ShowDocumentStatus />
            ) : selectedTab == 'bankdetails' ? (
              <ShowBankDetails />
            ) : selectedTab == 'claimletters' ? (
              <ShowClaimLetters />
            ) : selectedTab == 'actionrequired' ? (
              <ShowActionRequired
                actionRequiredDefaultOpen={actionRequiredDefaultOpen}
                setAlert={setAlert}
                clearAlert={clearAlert}
                status={claim_data.claim_status}
                documentsError={documentsError}
                update_progress={update_progress}
              />
            ) : (
              <></>
            )}

            {/* <ShowBankDetails/>
            <ShowClaimDetails/>
            <ShowDocumentStatus/>
            <ShowClaimLetters/> */}
          </div>
          <Ratings
            claimnumber={encclaimNumber}
            policynumber={encpolicyNumber}
            claimstatus={claim_data.claim_status}

          />
        </ClaimContext.Provider>

        {/* Show Alert warnings */}
        {alertStatus.status ? (
          <div>
            {!documentsError ? <div className="status_backlog"></div> : <></>}
            <AlertComponent alert_status={alertStatus} />
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  } else if (errStatus) {
    return (
      <div>
        <h6>Data not found</h6>
      </div>
    );
  }
  return (
    <div
      style={{
        height: '95vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {/* <div className="claimLoader"> */}
      {loading_status && loading_status ? <Loader /> : <></>}
      {/* <Loader1/> */}
      {/* </div> */}
    </div>
  );
};

export default MainApp1;
