import React from 'react'
import "./Alert.css"

const color_obj = {
    success:"rgb(46, 125, 50)",
    error:"rgb(211, 47, 47)",
    progress:"rgb(237, 108, 2)"
}
function AlertComponent({alert_status}) {
  let {msg , status , warning_status} = alert_status;
  if(status) {
  return (
    <div style={{background:color_obj[warning_status]}} className='alert_container'>
        <div className='alert_subcontainer'>
            <span className='alert_child'>{msg}</span>
            <span className='alert_progress'></span>
        </div>
    </div>
  )
  }else {
      return <></>
  }
}

export {AlertComponent} 
