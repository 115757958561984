// import React from 'react'
import React, { useContext, useState } from 'react';
import './Stories.css';
import hand from '../../Assets/goodbye.png';
import story1 from '../../Assets/story1.png';
import story2 from '../../Assets/story2.png';
import story3 from '../../Assets/story3.png';
import story4 from '../../Assets/story4.png';
import view from '../../Assets/view.svg';
import storytwo from '../../Assets/storyone.mp4';
import storyone from '../../Assets/storytwo.mp4';
import storythree from '../../Assets/storythree.mp4';
import storyfour from '../../Assets/storyfour.mp4';
import closestory from '../../Assets/close-story.png';
import logout from '../../Assets/logout.svg';

import { useNavigate } from 'react-router-dom';
import { ClaimContext } from '../../context/ClaimContext';

import Skeleton from 'react-loading-skeleton';
import LazyLoad from 'react-lazyload';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

const Story = () => {
  const navigate = useNavigate();
  const context = useContext(ClaimContext);
  const [show, setShow] = useState(false);

  const [play, setPlay] = useState(null);

  const playVideo = link => {
    setPlay(link);
    // setShow(true)
  };

  const reels = [
    {
      thumbmail: story1,
      videos: storyone,
      text: 'Do’s & Dont’s',
    },
    {
      thumbmail: story2,
      videos: storytwo,
      text: 'Whatsapp Claim',
    },
    {
      thumbmail: story3,
      videos: storythree,
      text: 'Faster Claim',
    },
    {
      thumbmail: story4,
      videos: storyfour,
      text: 'Post Closure',
    },
  ];

  const muted = () => {
    const vid = document.getElementById('vid');
    vid.muted = true;
  };

  const log_out = () => {
    // window.sessionStorage.removeItem('_ssid');
    // window.sessionStorage.removeItem('claims_data');
    window.sessionStorage.clear()
    navigate('/');
  };
  // const unmuted = () => {
  //   const vid = document.getElementById('vid');
  //   vid.muted = false;
  //   console.log('vid.muted');
  // };

  if (context.data) {
    return (
      <>
        <div>
          {/* <div className={show ? 'display' : 'hidden'}> */}
          <SwipeableDrawer style={{width:"100vw"}} open={show} onOpen={() => null} onClose={() => setShow(false)} className="drawer-container">
            <video
              id="vid"
              className="full overlay"
              src={play}
              loop
              autoPlay
              controls
            />
            <div onClick={() => setShow(!show)} className="video_close">
              <div className="close-icon">
                <img
                  src={closestory}
                  onClick={muted}
                  className="close-story"
                  alt=""
                />
              </div>
            </div>
          </SwipeableDrawer>

          {/* </div> */}

          <section className="stories-main">
             {/* <div className='top_header_container'>
              <p className="stories-text">
                <strong>
                  {/* Hi {context.data.patient_name}, <img src={hand} alt="" /> */}
                  {/* Hi {context.data.proposer_name} */}
                {/* </strong> */}
                {/* <br /> Hope you are doing fine */}
              {/* </p> */}
              {/* <div onClick={() => log_out()} className="log_out_btn"> */}
                {/* <img src={logout} /> */}
              {/* </div> */}
            {/* </div>  */}

            <ul className="story-ul">
              <li>
                <LazyLoad height={200}>
                  <div className="sto">
                    {reels.map((value, i) => {
                      return (
                        <button
                          key={i}
                          className="story-button"
                          onClick={() => playVideo(value.videos)}
                        >
                          <img
                            className="outer rotate"
                            src={value.thumbmail}
                            alt=""
                            onClick={() => {
                              setShow(!show);
                              // unmuted();
                            }}
                          />
                          <img src={view} className="play_btn" onClick={() => setShow(!show)}/>
                          <p
                            style={{
                              fontWeight: '400',
                              fontSize: '12px',
                              color: '#505050',
                              marginBottom: '10px',
                            }}
                          >
                            {value.text}
                          </p>
                        </button>
                      );
                    })}
                  </div>
                </LazyLoad>
              </li>
            </ul>
          </section>
        </div>
      </>
    );
  } else {
    return (
      <>
        <section className="stories-main">
          <p className="stories-text">
            <strong>
              <Skeleton width="14rem" height="12px" circle={false} count={1} />
              <Skeleton width="18rem" height="12px" circle={false} count={1} />
            </strong>
          </p>

          <ul className="story-ul">
            <li>
              <div className="skeleton-sto">
                {reels.map((value, i) => {
                  return (
                    <Skeleton
                      key={i}
                      width="4.5rem"
                      height="4.5rem"
                      circle
                      count={1}
                      
                    />
                  );
                })}
              </div>
            </li>
          </ul>
        </section>
      </>
    );
  }
};

export default Story;
