import React, { useContext } from 'react';
import './ClaimStatusTracker.css';

import { ClaimContext } from '../../context/ClaimContext';

import Skeleton from 'react-loading-skeleton';
// import preauthcorrectimage from '../Assets/preauthcorrectimage.jfif';
import preauthcorrectimage from '../../Assets/preauthcorrectimage.jfif';

const ClaimStatusTracker = props => {
  const sta = props.statu;
  const context = useContext(ClaimContext);

  const card_status = (status, type) => {
    // console.log('status', status)
    // console.log('type', type)

    if((type== "PreAuth"||type== "PREAUTH"||type.toLowerCase()=="preauth"||type.toUpperCase()=="PRE-AUTH DISCHARGE")){
      const statuss = {
        Filed: 'green',
        Approved: '#d8d3d3',
        Verification: '#d8d3d3',
        Rejected: '#d8d3d3',
        // Disbursed: '#d8d3d3',
      };
      if (status === 'Claim Approved'||status === 'PreAuth Claim Approved'||status ==='Pre- Authorization Approved'||status ==='Claim Paid') {
        statuss.Filed = 'green';
        statuss.Approved = 'Green';
        statuss.Verification = 'green';
        statuss.Rejected = '#d8d3d3';
        // statuss.Disbursed = '#d8d3d3';

    
      } else if (status === 'Documents Verification'||status === 'Document Verification' || status === 'Bill verification'||status === 'PreAuth Documents Verification'||status === 'PreAuth Document Verification' || status === 'PreAuth Bill verification'||status === 'PreAuth Additional documents request') {
        statuss.Filed = 'green';
        statuss.Verification = 'Orange';
        statuss.Approved = '#d8d3d3';
        statuss.Rejected = '#d8d3d3';
        // statuss.Disbursed = '#d8d3d3';
      } else if (status == 'Claim Rejected'||status == 'Pre- Authorization Repudiated'||status == 'PreAuth Claim Rejected') {
        statuss.Filed = 'green';
        statuss.Rejected = 'Red';
        statuss.Verification = 'green';
        statuss.Approved = 'green';
        statuss.Disbursed = '#d8d3d3';

        // statuss.Approved = '#d8d3d3';
        // statuss.Disbursed = '#d8d3d3';
      } else if (status === 'Claim Paid'||status === 'Claim Closed'||status === 'Close'||status==='PreAuth Claim Closed') {
        statuss.Filed = 'green';
        statuss.Approved = 'green';
        statuss.Verification = 'green';
        statuss.Rejected = 'green';
        // statuss.Disbursed = 'green';
      }else{
        statuss.Filed = 'green';
        statuss.Verification = 'green';
        statuss.Approved = '#d8d3d3';
        statuss.Rejected = '#d8d3d3';
        // statuss.Disbursed = '#d8d3d3';
      }
      return (
        <section className="claim-status-main_preauth">
          <div className="step">
            <div>
              <div
                style={{
                  backgroundColor: statuss.Filed,
                }}
                className={
                  sta === 'Initiated'
                    ? 'circle first_step blow_step_active'
                    : 'circle first_step'
                }
              />
            </div>
            <div className="align-words">
              <div className="title">PreAuth Req Received</div>
            </div>
          </div>
          <div className="step">
            <div>
              <div
                style={{ background: statuss.Verification }}
                className={
                  sta === 'Verification'
                    ? 'circle verification_step blow_step_active'
                    : 'circle verification_step'
                }
                id="verification"
              />
            </div>
            <div className="align-words">
              <div className="title">PreAuth Document Verification</div>
            </div>
          </div>
          {/* {sta !== 'Verification' && ( */}

          <div className="step">
            <div>
              <div
                style={{ background: (context.data.claim_status == 'Claim Rejected'|| context.data.claim_status == 'Pre- Authorization Repudiated')
                ? statuss.Rejected
                : statuss.Approved }}

                // style={{ background: statuss.Approved }}

                className="circle"
              />
            </div>
            <div className="align-words">
              <div className="title">
                {(context.data.claim_status == 'Claim Rejected'|| context.data.claim_status == 'Pre- Authorization Repudiated')
                  ? 'PreAuth Rejected'
                  : 'PreAuth Approved'}
              </div>
            </div>
          </div>
  
          <style jsx="true">
            {`
              // @keyframes blow {
              //   from {
              //     transform: scale(0.5);
              //     transform: scale(1);
              //   }
              //   to {
              //     transform: scale(3);
              //     transform: scale(0.5);
              //   }
              // }
              .first_step:after {
                content: ' ';
                position: absolute;
                display: block;
                top: 1px;
                right: 50%;
                bottom: 1px;
                left: 50%;
                height: 100%;
                width: 1px;
                transform: scale(1, 2);
                transform-origin: 50% -90%;
                background-color: ${statuss.Filed};
              }
              .verification_step:after {
                content: ' ';
                position: absolute;
                display: block;
                top: 1px;
                right: 50%;
                bottom: 1px;
                left: 50%;
                height: 100%;
                width: 1px;
                transform: scale(1, 2);
                transform-origin: 50% -90%;
                background-color: ${statuss.Approved};
              }
              .blow_step_active {
                animation: blow 1s 1;
              }
              .claim_status:after {
                content: ' ';
                position: absolute;
                display: block;
                top: 1px;
                right: 50%;
                bottom: 1px;
                left: 50%;
                height: 100%;
                width: 1px;
                transform: scale(1, 2);
                transform-origin: 50% -100%;
                background-color: ${statuss.Approved};
              }
              .claim_rejected:after {
                position: absolute;
                display: block;
                top: 1px;
                right: 50%;
                bottom: 1px;
                left: 50%;
                height: 100%;
                width: 1px;
                transform: scale(1, 2);
                transform-origin: 50% -100%;
                background-color: ${statuss.Approved};
              }
            `}
          </style>
        </section>
      );

     
    }else if(type== "cashless"||type=="CASHLESS"||type=="Cashless"){

      const statuss = {
        Filed: 'green',
        Approved: '#d8d3d3',
        Verification: '#d8d3d3',
        Rejected: '#d8d3d3',
        Disbursed: '#d8d3d3',
      };
      if (status === 'Claim Approved'||status === 'Cashless Claim Closed') {
        statuss.Filed = 'green';
        statuss.Approved = 'Green';
        statuss.Verification = 'green';
        statuss.Rejected = '#d8d3d3';
        statuss.Disbursed = '#d8d3d3';
      } else if (status === 'Documents Verification'||status === 'Document Verification' || status === 'Bill verification'||status === 'Cashless Documents Verification'||status === 'Cashless Document Verification' || status === 'Cashless Additional documents request') {
        statuss.Filed = 'green';
        statuss.Verification = 'Orange';
        statuss.Approved = '#d8d3d3';
        statuss.Rejected = '#d8d3d3';
        statuss.Disbursed = '#d8d3d3';
      } else if (status === 'Claim Rejected'||status === 'Pre- Authorization Repudiated') {
        statuss.Filed = 'green';
        statuss.Rejected = 'red';
        statuss.Verification = 'green';
        statuss.Approved = '#d8d3d3';
        statuss.Disbursed = '#d8d3d3';
      } else if (status === 'Claim Paid'||status === 'Claim Closed'||status === 'Close'||status === 'Pre- Authorization Repudiated') {
        statuss.Filed = 'green';
        statuss.Approved = 'green';
        statuss.Verification = 'green';
        statuss.Rejected = 'green';
        statuss.Disbursed = 'green';
      }else{
        statuss.Filed = 'green';
        statuss.Verification = 'green';
        statuss.Approved = '#d8d3d3';
        statuss.Rejected = '#d8d3d3';
        statuss.Disbursed = '#d8d3d3';
      }
      return (
        <>
        {/* <div>
        <div style={{ display: 'flex', alignItems: 'center', margin:'10px' }}>
          <p style={{ fontSize: '12px' }}>Pre-Auth Approved</p>
          <img
            src={preauthcorrectimage}
            alt="preauthcorrectimage"
            style={{ width: '30px' }}
          />
        </div>
    </div> */}
        <section className="claim-status-main">

        <div className="step">
            <div>
              <div
                style={{
                  backgroundColor: statuss.Filed,
                }}
                className={
                  sta === 'Initiated'
                    ? 'circle first_step blow_step_active'
                    : 'circle first_step'
                }
              />
            </div>
            <div className="align-words">
              <div className="title">Pre-Auth Approved</div>
            </div>
          </div>




          <div className="step">
            <div>
              <div
                style={{
                  backgroundColor: statuss.Filed,
                }}
                className={
                  sta === 'Initiated'
                    ? 'circle first_step blow_step_active'
                    : 'circle first_step'
                }
              />
            </div>
            <div className="align-words">
              <div className="title">Document From Hospital</div>
            </div>
          </div>
          <div className="step">
            <div>
              <div
                style={{ background: statuss.Verification }}
                className={
                  sta === 'Verification'
                    ? 'circle verification_step blow_step_active'
                    : 'circle verification_step'
                }
                id="verification"
              />
            </div>
            <div className="align-words">
              <div className="title">Document Verification</div>
            </div>
          </div>
          {/* {sta !== 'Verification' && ( */}
          <div className="step">
            <div>
              <div
                style={{
                  background:
                    (context.data.claim_status === 'Claim Rejected'|| context.data.claim_status === 'Pre- Authorization Repudiated')
                      ? statuss.Rejected
                      : statuss.Approved,
                }}
                className={
                  (context.data.claim_status === 'Claim Rejected'|| context.data.claim_status === 'Pre- Authorization Repudiated')
                    ? 'circle claim_rejected blow_step_active'
                    : 'circle claim_status'
                }
              />
            </div>
            <div className="align-words">
              <div className="title">
                {(context.data.claim_status === 'Claim Rejected'|| context.data.claim_status === 'Pre- Authorization Repudiated')
                  ? 'Claim Rejected'
                  : 'Claim Approved'}
              </div>
            </div>
          </div>
          {/* )} */}
  
          {(sta !== 'Claim Rejected'|| context.data.claim_status === 'Pre- Authorization Repudiated') && (
            <div className="step">
              <div>
                <div
                  style={{ background: statuss.Disbursed }}
                  className="circle"
                />
              </div>
              <div className="align-words">
                <div className="title">Claim Paid</div>
              </div>
            </div>
          )}
  
          <style jsx="true">
            {`
              // @keyframes blow {
              //   from {
              //     transform: scale(0.5);
              //     transform: scale(1);
              //   }
              //   to {
              //     transform: scale(3);
              //     transform: scale(0.5);
              //   }
              // }
              .first_step:after {
                content: ' ';
                position: absolute;
                display: block;
                top: 1px;
                right: 50%;
                bottom: 1px;
                left: 50%;
                height: 100%;
                width: 1px;
                transform: scale(1, 2);
                transform-origin: 50% -90%;
                background-color: ${statuss.Filed};
              }
              .verification_step:after {
                content: ' ';
                position: absolute;
                display: block;
                top: 1px;
                right: 50%;
                bottom: 1px;
                left: 50%;
                height: 100%;
                width: 1px;
                transform: scale(1, 2);
                transform-origin: 50% -90%;
                background-color: ${statuss.Approved};
              }
              .blow_step_active {
                animation: blow 1s 1;
              }
              .claim_status:after {
                content: ' ';
                position: absolute;
                display: block;
                top: 1px;
                right: 50%;
                bottom: 1px;
                left: 50%;
                height: 100%;
                width: 1px;
                transform: scale(1, 2);
                transform-origin: 50% -100%;
                background-color: ${statuss.Disbursed};
              }
              .claim_rejected:after {
                position: absolute;
                display: block;
                top: 1px;
                right: 50%;
                bottom: 1px;
                left: 50%;
                height: 100%;
                width: 1px;
                transform: scale(1, 2);
                transform-origin: 50% -100%;
                background-color: ${statuss.Approved};
              }
            `}
          </style>
        </section>
        </>
      );
            }
    
    else{

    const statuss = {
      Filed: 'green',
      Approved: '#d8d3d3',
      Verification: '#d8d3d3',
      Rejected: '#d8d3d3',
      Disbursed: '#d8d3d3',
    };
    if (status === 'Claim Approved') {
      statuss.Filed = 'green';
      statuss.Approved = 'Green';
      statuss.Verification = 'green';
      statuss.Rejected = '#d8d3d3';
      statuss.Disbursed = '#d8d3d3';
    } else if (status === 'Documents Verification'||status === 'Document Verification' || status === 'Bill verification') {
      statuss.Filed = 'green';
      statuss.Verification = 'Orange';
      statuss.Approved = '#d8d3d3';
      statuss.Rejected = '#d8d3d3';
      statuss.Disbursed = '#d8d3d3';
    } else if (status === 'Claim Rejected'||status === 'Pre- Authorization Repudiated') {
      statuss.Filed = 'green';
      statuss.Rejected = 'Red';
      statuss.Verification = 'green';
      statuss.Approved = 'green';
      statuss.Disbursed = '#d8d3d3';
    } else if (status === 'Claim Paid'||status === 'Claim Closed'||status === 'Close') {
      statuss.Filed = 'green';
      statuss.Approved = 'green';
      statuss.Verification = 'green';
      statuss.Rejected = 'green';
      statuss.Disbursed = 'green';
    }else{
      statuss.Filed = 'green';
      statuss.Verification = 'green';
      statuss.Approved = '#d8d3d3';
      statuss.Rejected = '#d8d3d3';
      statuss.Disbursed = '#d8d3d3';
    }
    return (
      <section className={`${status === 'Claim Rejected'?'claim-status-main_preauth':'claim-status-main'}`}>
        <div className="step">
          <div>
            <div
              style={{
                backgroundColor: statuss.Filed,
              }}
              className={
                sta === 'Initiated'
                  ? 'circle first_step blow_step_active'
                  : 'circle first_step'
              }
            />
          </div>
          <div className="align-words">
            <div className="title">Claim Intimated</div>
          </div>
        </div>
        {/* {console.log("context claimStatusTracker", context)} */}
        <div className="step">
          <div>
            <div
              style={{ background: statuss.Verification }}
              className={
                sta === 'Verification'
                  ? 'circle verification_step blow_step_active'
                  : 'circle verification_step'
              }
              id="verification"
            />
          </div>
          <div className="align-words">
            <div className="title">Document Verification</div>
          </div>
        </div>
        {/* {sta !== 'Verification' && ( */}
        <div className="step">
          <div>
            <div
              style={{
                background:
                  (context.data.claim_status === 'Claim Rejected'|| context.data.claim_status === 'Pre- Authorization Repudiated')
                    ? statuss.Rejected
                    : statuss.Approved,
              }}
              className={
                (context.data.claim_status === 'Claim Rejected'|| context.data.claim_status === 'Pre- Authorization Repudiated')
                  ? 'circle claim_rejected blow_step_active'
                  : (context.data.external_status === 'Reimb - Closure Letter') ? 'circle status' : 'circle claim_status'
              }
            />
          </div>
          <div className="align-words">
            <div className="title">
              {(context.data.claim_status === 'Claim Rejected'|| context.data.claim_status === 'Pre- Authorization Repudiated')
                ? 'Claim Rejected'
                : context.data.external_status === 'Reimb - Closure Letter' ? 'Claim Closed' : 'Claim Approved'}
            </div>
          </div>
        </div>
        {/* )} */}

        {(sta !== 'Claim Rejected'|| context.data.claim_status === 'Pre- Authorization Repudiated') && (
          <div className="step">
            <div>
              <div
                style={{ background: statuss.Disbursed }}
                className={`${context.data.external_status === 'Reimb - Closure Letter' ? "" : "circle"}`}
              />
            </div>
            <div className="align-words">
              <div className="title">{context.data.external_status === 'Reimb - Closure Letter'? "" :"Amount Disbursed"}</div>
            </div>
          </div>
        )}

        <style jsx="true">
          {`
            // @keyframes blow {
            //   from {
            //     transform: scale(0.5);
            //     transform: scale(1);
            //   }
            //   to {
            //     transform: scale(3);
            //     transform: scale(0.5);
            //   }
            // }
            .first_step:after {
              content: ' ';
              position: absolute;
              display: block;
              top: 1px;
              right: 50%;
              bottom: 1px;
              left: 50%;
              height: 100%;
              width: 1px;
              transform: scale(1, 2);
              transform-origin: 50% -90%;
              background-color: ${statuss.Filed};
              z-index:1;
            }
            .verification_step:after {
              content: ' ';
              position: absolute;
              display: block;
              top: 1px;
              right: 50%;
              bottom: 1px;
              left: 50%;
              height: 100%;
              width: 1px;
              transform: scale(1, 2);
              transform-origin: 50% -90%;
              background-color: ${statuss.Approved};
              z-index:1;
            }
            .blow_step_active {
              animation: blow 1s 1;
            }
            .claim_status:after {
              content: ' ';
              position: absolute;
              display: block;
              top: 1px;
              right: 50%;
              bottom: 1px;
              left: 50%;
              height: 100%;
              width: 1px;
              transform: scale(1, 2);
              transform-origin: 50% -100%;
              background-color: ${statuss.Disbursed};
              z-index:1;
            }
            .claim_rejected:after {
              position: absolute;
              display: block;
              top: 1px;
              right: 50%;
              bottom: 1px;
              left: 50%;
              height: 100%;
              width: 1px;
              transform: scale(1, 2);
              transform-origin: 50% -100%;
              background-color: ${statuss.Approved};
              z-index:1;
            }
          `}
        </style>
      </section>
    );
          }
  };
  if (context.data) {
    return (
      <div className={`claim_status_tracker_container  ${(context.data.claim_type=="Cashless"||context.data.claim_type=="CASHLESS")?"claim_status_tracker_containerCashless":""}`}>
        <p className="claim-status-tracker-text">Claim Status Tracker</p>
        {card_status(context.data.external_status === 'Reimb - Closure Letter' ? 'Claim Approved' : context.data.claim_status, context.data.claim_type)}
      </div>
    );
  } else {
    return (
      <>
        <p className="claim-status-tracker-text">
          <Skeleton width="12rem" height="1rem" circle={false} count={1} />
        </p>
        <section className="claim-status-main">
          <div className="step">
            <div>
              <div>
                <Skeleton width="1rem" height="1rem" circle count={1} />
              </div>
            </div>
            <div className="align-words">
              <div className="title">
                <Skeleton width="8rem" height="1rem" circle={false} count={1} />
              </div>

              <div className="caption">
                <span className="" />
                <Skeleton width="6rem" height="1rem" circle={false} count={1} />
              </div>
            </div>
          </div>
          <div className="step">
            <div>
              <div>
                <Skeleton width="1rem" height="1rem" circle count={1} />
              </div>
            </div>
            <div className="align-words">
              <div className="title">
                <Skeleton width="8rem" height="1rem" circle={false} count={1} />
              </div>

              <div className="caption">
                <span className="" />
                <Skeleton width="6rem" height="1rem" circle={false} count={1} />
              </div>
            </div>
          </div>
          <div className="step">
            <div>
              <div>
                <Skeleton width="1rem" height="1rem" circle count={1} />
              </div>
            </div>
            <div className="align-words">
              <div className="title">
                <Skeleton width="8rem" height="1rem" circle={false} count={1} />
              </div>

              <div className="caption">
                <span className="" />
                <Skeleton width="6rem" height="1rem" circle={false} count={1} />
              </div>
            </div>
          </div>
          <div className="step">
            <div>
              <div>
                <Skeleton width="1rem" height="1rem" circle count={1} />
              </div>
            </div>
            <div className="align-words">
              <div className="title">
                <Skeleton width="8rem" height="1rem" circle={false} count={1} />
              </div>

              <div className="caption">
                <span className="" />
                <Skeleton width="6rem" height="1rem" circle={false} count={1} />
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
};

export default ClaimStatusTracker;
