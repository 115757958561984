import React from 'react';
import { useNavigate } from 'react-router-dom';
// import logoutImg from '../../Assets/logout.svg';
import hdfc from '../../Assets/hdfclogo.png';
import logoutImg from '../../Assets/logout.svg';
import {returnSingleName} from '../../utility'

const Header = ({ name, fineText, pcText }) => {
  const navigate = useNavigate();

  const handleLogoutPolicy = () => {
    window.sessionStorage.clear();
    navigate('/');
  };

  return (
    <div className="policy_details_header">
      <div className="top_header_container">
        <div className="top_header_container_1">
          <div className="claimStatus_top">
            <p>
              Hello {` `}
              {returnSingleName(name) || ''}
            </p>
            {/* <img src={goodByImg} alt="goodByImg" /> */}
          </div>
          <p style={{ color: '#7C7C7C', fontSize: 14 }}>{fineText || ''}</p>
        </div>
        <img
          src={hdfc}
          alt=""
          style={{ cursor: 'pointer' }}
          onClick={() => {
            sessionStorage.clear()
            navigate('/policyDetails');
          }}
        />
      </div>
      {/* <div
        className="policy_logout"
        onClick={handleLogoutPolicy}
        style={{ marginTop: '-8px'}}
      >
        <img src={logoutImg} alt="" className="policy_logout_img"/>
      </div> */}
      <span
        style={{
          fontSize: '16px',
          fontWeight: 400,
          paddingBottom: '10px',
          display: 'inline-block',
        }}
      >
        {pcText || ''}
      </span>
    </div>
  );
};

export default Header;
