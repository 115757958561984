import React, { useState, useContext, useEffect } from 'react';
import './ClaimDetails.css';
import downarrow from '../../Assets/downarrow.svg';
// import claimdetail from '../../Assets/claim-detail.svg';
import claimdetail from '../../Assets/claimDetailsPng.png';

import { ClaimContext } from '../../context/ClaimContext';
import Skeleton from 'react-loading-skeleton';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import DocumentUpload from '../DocumentUpload/DocumentUpload';


const ActionRequired = ({actionRequiredDefaultOpen, setAlert,clearAlert,status,documentsError,update_progress}) => {

  // console.log('Action Required',actionRequiredDefaultOpen);
  const context = useContext(ClaimContext);

  const [show, setShow] = useState(false);

  useEffect(() => {
    if(actionRequiredDefaultOpen==true){
      document.getElementById('actionRequiredDefaultOpenid').click();
    }
    
  }, [actionRequiredDefaultOpen])
  
  if (context.data.required_documents) {
    return (
      <>
        {/* <section className="claim-detail-main">
          <div className="claim-details" onClick={() => setShow(!show)}>
            <div className="claim-details-sub">
              <img src={claimdetail} alt="" />
              <p className="claim-details-text">
                <span className="heading">Claim Details</span> <br />
                Information related to claim
              </p>
            </div>
            <div className="float-right">
              {show ? (
                <img src={uparrow} alt="" />
              ) : (
                <img src={downarrow} alt="" />
              )}
            </div>
          </div>
          <div>
            <div className={show ? "claim-detail-inner" : "hide"}>
              <hr />
              <div className="person-detail">
                <p className="claim-call">Patient name</p>
                <p className="claim-caller">{context.data.patient_name}</p>
              </div>
              <div className="person-detail">
                <p className="claim-call">Hospital name</p>
                <p className="claim-caller">{context.data.hospital_name}</p>
              </div>
              <div className="person-detail">
                <p className="claim-call">Relationship</p>
                <p className="claim-caller">{context.data.patient_relation}</p>
              </div>
              <div className="person-detail">
                <p className="claim-call">Ailment type</p>
                <p className="claim-caller">{context.data.temp_ailmentname}</p>
              </div>
              <div className="person-detail">
                <p className="claim-call">Date of admission</p>
                <p className="claim-caller">{context.data.date_admission}</p>
              </div>
              <div className="person-detail">
                <p className="claim-call">Date of discharge</p>
                <p className="claim-caller">{context.data.date_discharge}</p>
              </div>
              <div className="person-detail">
                <p className="claim-call">Policy type</p>
                <p className="claim-caller">{context.data.policy_type}</p>
              </div>
              <div className="person-detail">
                <p className="claim-call">Policy number</p>
                <p className="claim-caller">{context.data.policy_number}</p>
              </div>
            </div>
          </div>
        </section> */}
        <Accordion className='accordianContainer'>
          <AccordionSummary
            aria-controls="panel1a-content"
            expandIcon={<img src={downarrow} alt="" />}
            // id="panel1a-header"
            id="actionRequiredDefaultOpenid"
          >
            <div className="claim-details-sub">
              <img src={claimdetail} alt="" />
              <p className="claim-details-text">
                <span className="heading">Action Required</span> <br />
                Please Upload Following Documents
              </p>
            </div>
          </AccordionSummary>
          <hr />
          <AccordionDetails>
           <DocumentUpload setAlert={setAlert} clearAlert={clearAlert} update_progress={update_progress}/>
          </AccordionDetails>
        </Accordion>
      </>
    );
  } else {
    return (
      <></>
    );
  }
};

export default ActionRequired;
