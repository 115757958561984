import React, { useState, useContext } from 'react';
import './ClaimDetails.css';
import downarrow from '../../Assets/downarrow.svg';
// import claimdetail from '../../Assets/claim-detail.svg';
import claimdetail from '../../Assets/claimDetailsPng.png';


import { ClaimContext } from '../../context/ClaimContext';
import Skeleton from 'react-loading-skeleton';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import GlobalStyles from '@mui/material/GlobalStyles';
import {returnSingleName, indianCurrencyFormat} from '../../utility'


const ClaimDetailsDesktop = () => {
  const context = useContext(ClaimContext);

  const [show, setShow] = useState(false);
  const [showDetails, setShowDetails] = useState(false);


  if (context.data) {
    return (
      <>
        {/* <section className="claim-detail-main">
          <div className="claim-details" onClick={() => setShow(!show)}>
            <div className="claim-details-sub">
              <img src={claimdetail} alt="" />
              <p className="claim-details-text">
                <span className="heading">Claim Details</span> <br />
                Information related to claim
              </p>
            </div>
            <div className="float-right">
              {show ? (
                <img src={uparrow} alt="" />
              ) : (
                <img src={downarrow} alt="" />
              )}
            </div>
          </div>
          <div>
            <div className={show ? "claim-detail-inner" : "hide"}>
              <hr />
              <div className="person-detail">
                <p className="claim-call">Patient name</p>
                <p className="claim-caller">{context.data.patient_name}</p>
              </div>
              <div className="person-detail">
                <p className="claim-call">Hospital name</p>
                <p className="claim-caller">{context.data.hospital_name}</p>
              </div>
              <div className="person-detail">
                <p className="claim-call">Relationship</p>
                <p className="claim-caller">{context.data.patient_relation}</p>
              </div>
              <div className="person-detail">
                <p className="claim-call">Ailment type</p>
                <p className="claim-caller">{context.data.temp_ailmentname}</p>
              </div>
              <div className="person-detail">
                <p className="claim-call">Date of admission</p>
                <p className="claim-caller">{context.data.date_admission}</p>
              </div>
              <div className="person-detail">
                <p className="claim-call">Date of discharge</p>
                <p className="claim-caller">{context.data.date_discharge}</p>
              </div>
              <div className="person-detail">
                <p className="claim-call">Policy type</p>
                <p className="claim-caller">{context.data.policy_type}</p>
              </div>
              <div className="person-detail">
                <p className="claim-call">Policy number</p>
                <p className="claim-caller">{context.data.policy_number}</p>
              </div>
            </div>
          </div>
        </section> */}
         {/* <GlobalStyles styles={{.accordianContainer : { color: 'red' } }} /> */}
          <div
            
            id={`${context.data.required_documents ? "panel1a-header1":"panel1a-header"}`}
          >
            <div className="claim-details-sub" onClick={()=>setShowDetails(!showDetails)}>
              <img src={claimdetail} alt="" />
              <p className="claim-details-text">
                <span className="heading">Claim Details</span> <br />
                Information related to claim
              </p>
            </div>
          </div>
         
      </>
    );
  } else {
    return (
      <div className="claim-details" onClick={() => setShow(!show)}>
        <div className="claim-details-sub">
          <Skeleton width="3rem" height="3rem" circle={false} count={1} />
          <p className="claim-details-text">
            <span className="heading">
              <Skeleton width="8rem" height="1rem" circle={false} count={1} />
            </span>
            <br />
            <Skeleton width="8rem" height="0.4rem" circle={false} count={1} />
          </p>
        </div>
        <div className="float-right">
          <Skeleton width="0.8rem" height="0.8rem" circle={false} count={1} />
        </div>
      </div>
    );
  }
};

export default ClaimDetailsDesktop;

export const ShowClaimDetails = () => {
  const context = useContext(ClaimContext);
  return (
    <div className='bankdetailsdesktop'>
      {(context.data.claim_type.toLowerCase()==='cashless'|| context.data.claim_type.toLowerCase()==='preauth'|| context.data.claim_type==='PRE-AUTH DISCHARGE')&&<p className='cashlessClaimDetailText'>Claim Details</p>}
  <div className='bankdetailsdesktopviewClaim'>
 <div>
 <div className="person-detail">
    <p className="claim-call">Patient name</p>
    <p className="claim-caller"> : {returnSingleName(context.data.patient_name) ||"N/A"}</p>
  </div>
  <div className="person-detail">
    <p className="claim-call">Hospital name</p>
    <p className="claim-caller"> : {context.data.hospital_name||"N/A"}</p>
  </div>
  <div className="person-detail">
    <p className="claim-call">Relationship</p>
    <p className="claim-caller"> : {context.data.patient_relation ||"N/A"}</p>
  </div>
  
  <div className="person-detail">
    <p className="claim-call">Ailment type</p>
    <p className="claim-caller"> : {context.data.temp_ailmentname ||"N/A"}</p>
  </div>
 </div>
<div>
<div className="person-detail">
    <p className="claim-call">Date of admission</p>
    <p className="claim-caller"> : {context.data.date_admission ||"N/A"}</p>
  </div>
  <div className="person-detail">
    <p className="claim-call">Date of discharge</p>
    <p className="claim-caller"> : {context.data.date_discharge ||"N/A"}</p>
  </div>
  <div className="person-detail">
    <p className="claim-call">Product name</p>
    <p className="claim-caller"> : {context.data.product_name ||"N/A"}</p>
  </div>
  <div className="person-detail">
    <p className="claim-call">Proposer name</p>
    <p className="claim-caller"> : {context.data.proposer_name ||"N/A"}</p>
  </div>
</div>
<div>
<div className="person-detail">
    <p className="claim-call">Claim Intimation</p>
    <p className="claim-caller"> : {context.data.claim_intimation ||"N/A"}</p>
  </div>
  
  <div className="person-detail">
    <p className="claim-call">Claim Amount</p>
    <p className="claim-caller">: {`₹ ${indianCurrencyFormat(context.data.approved_amt||context.data.total_claimamount)}` ||"N/A"}</p>
  </div>
  <div className="person-detail">
    <p className="claim-call">Policy number</p>
    <p className="claim-caller"> : {context.data.policy_number ||"N/A"}</p>
  </div>
</div>
  </div>
   </div>
  )
}




