import React, { useState, useEffect } from 'react';
import '../Claims/Claims.css';
// import hand from '../../Assets/goodbye.png';
import empty from '../../Assets/empty.svg';
// import heart from '../../Assets/Group 8186.svg';
import { useNavigate, useParams } from 'react-router-dom';
import ServerDown from '../../Assets/server-down.png';
// import arrow from '../../Assets/redirect.svg';
import { env } from '../../config/env';
// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import downarrow from '../../Assets/downarrow.svg';
// import claimstatus from '../../Assets/claim-status.svg';
import { dateSorting , status_obj} from '../../utility';
import Loader1 from '../Loader/Loader1'; 
import ClaimStatus from '../ClaimStatus/ClaimStatus';
import hdfc from '../../Assets/hdfclogo.png';
import ReactGA from 'react-ga';


import PolicyDetails from '../PolicyDetails/PolicyDetails';
import Claimsjson from "./claimDetails.json";



// const accepted_claim_type = {
//   Reimbursment: true,
//   Cashless: true,
//   SUPPLEMENTRY: true,
// };

// const claims_categories = {
//   Verification: 'In Progress Claims',
//   Initiated: 'In Progress Claims',
//   Rejected: 'Rejected Claims',
//   Approved: 'Approved Claims',
//   'Approved & Disbursed': 'Approved Claims',
// };

const claims_categories = {
  'Verification': 'In Progress Claims',
  'Initiated': 'In Progress Claims',
  'Rejected': 'Rejected Claims',
  'Approved': 'Approved Claims',
  'Approved & Disbursed': 'Approved Claims',
  'Additional documents request':'In Progress Claims',
  'PreAuth Additional documents request':'In Progress Claims',
  'Cashless Additional documents request':'In Progress Claims',
  'Bill verification':'In Progress Claims',
  'PreAuth Bill verification':'In Progress Claims',
  'Claim Approved':'Approved Claims',
  'Claim Closed':'Approved Claims',
  'Cashless Claim Closed':'Approved Claims',
  'PreAuth Claim Closed':'Approved Claims',
  'Claim Intimated':'In Progress Claims',
  'Claim Paid':'Approved Claims',
  'Claim Rejected':'Rejected Claims',
  'Close':'Approved Claims',
  'Document awaited':'In Progress Claims',
  'Document Verification':'In Progress Claims',
  'Cashless Document Verification':'In Progress Claims',
  'PreAuth Document Verification':'In Progress Claims',
  'Documents awaited':'In Progress Claims',
  'Documents Verification':'In Progress Claims',
  'Cashless Documents Verification':'In Progress Claims',
  'PreAuth Documents Verification':'In Progress Claims',
  'Not valid status':'In Progress Claims',
  'OUTCALLING':'In Progress Claims',
  'Pre- Authorization Approved':'In Progress Claims',
  'Pre- Authorization Repudiated':'Rejected Claims',
  'Quality check':'In Progress Claims',
  'Query Request':'In Progress Claims',
  'Under external verification':'In Progress Claims',

};

let rejected = '#fce8e6',
  approved = '#e6f4ea',
  initiated = '#e8f0fe',
  verification = '#fda766';

const Claims = ({ setClaims, existedClaimsData, setClaimsList }) => {
  // window.scrollTo({ top: 0, left: 0 });

  const {usersPolicyNumber} = useParams();

  // console.log('policyNo', usersPolicyNumber);
  
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [emptyData, setEmptyData] = useState(false);
  const [loading_status, setLoadingStatus] = useState(false);
  const [deviceSize, changeDeviceSize] = useState(window.screen.width);


  const setClaimData = (claim) => {
    // console.log('claim in set claim', claim)
    setClaims(claim, () => navigate('/status'));
  };

  const extract_claimid_policy = policy_details => {
    return policy_details.map(claim => {
      return { claim_id: claim.ClaimRefNo, policy_number: claim.PolicyNo };
    });
  };

  // const fetch_claim_details_list = async claims_creds_arr => {
  //   if (claims_creds_arr.length !== 0) {
  //     try {
  //       const requestOptions = {
  //         method: 'POST',
  //         headers: {
  //           'content-type': 'application/json',
  //           token: window.sessionStorage.getItem('_ssid'),
  //         },
  //         body: JSON.stringify({ claims: claims_creds_arr }),
  //       };
  //       const res = await fetch(
  //         `${env.SERVER_URL}/get_claims/get_claim_details_list`,
  //         requestOptions
  //       );

  //       const final_response = await res.json();
  //       if (final_response.status && final_response.data.length !== 0) {
  //         distribute_claims(final_response.data);
  //       } else {
  //         setEmptyData(true);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //       setError(true);
  //     }
  //   } else {
  //     setEmptyData(true);
  //   }
  // };

  

  const fetchClaimData =() => {

    ReactGA.event({
      category: 'claim_detail_fetched',
      action: 'claim_detail_fetched',
      label:'claim_detail_fetched'
    })

    window.sessionStorage.removeItem('claim');
    window.sessionStorage.removeItem('claims_data');
   
    // if (!existedClaimsData) {
      let existed_data = JSON.parse(window.sessionStorage.getItem('claims_data'));
      
      async function fetchData() {
        const requestOptions = {
          method: 'GET',
          headers: {
            'content-type': 'application/json',
            token: window.sessionStorage.getItem('_ssid'),
          },
        };
        try {
          setLoadingStatus("true");
          let policyforclaim = window.sessionStorage.getItem('policyforclaim')
          let claimresp = JSON.parse(window.sessionStorage.getItem('claimresp'))
          console.log("pp", policyforclaim)
          console.log("cc", claimresp)


         let final_response;

        ////  for real data
          if(claimresp && policyforclaim==usersPolicyNumber){
             final_response = await claimresp;
          }else{
            const res = await fetch(
              `${env.SERVER_URL}/get_claims/claims/${usersPolicyNumber}`,
              requestOptions
            );
             final_response = await res.json();
          }

          if (final_response.status && final_response.data.length !== 0) {
            window.sessionStorage.setItem('claimresp', JSON.stringify(final_response));
            window.sessionStorage.setItem('policyforclaim', usersPolicyNumber);

            distribute_claims(final_response.data);

                    ////  for real data//


        //// for testing only
          // final_response = Claimsjson
          // console.log('final_response', final_response)
          //   if (final_response) {
          //     setTimeout(() => {
          //       window.sessionStorage.setItem('claimresp', JSON.stringify(final_response));
          //     window.sessionStorage.setItem('policyforclaim', usersPolicyNumber);
          //     distribute_claims(final_response);
          //     }, 3000);

        //// for testing only //

              
          } else {
            setEmptyData(true);
            // setLoadingStatus(false);
          }
          // distribute_claims(Claimsjson);
          // // setEmptyData(true);
          // setLoadingStatus("false");
        } catch (error) {
          // console.log(error);
          setError(true);
          setLoadingStatus(false);
        }
      }

      if (existed_data) {
        let data = existed_data;
        if (data.length !== 0) {
          setClaimsList(data);
          setLoadingStatus(false);
          return;
        }
      }
      fetchData();
    // }
  }
  

  useEffect(() => {
    let mounted = true;
   fetchClaimData()
  return ()=> {mounted = false}
  },[]);

useEffect(() => {
  let mounted = true;
  fetchClaimData()
 return ()=> {mounted = false}
  },[usersPolicyNumber]);
  // useEffect(() => {
  //   fetchClaimData()
  //   },[usersPolicyNumber]);

  // const distribute claims according to type

  const distribute_claims = claims => {
    let claims_container = {
      'In Progress Claims': [],
      'Rejected Claims': [],
      'Approved': [],
      'Others': [],
    };
    let sorted_claims = dateSorting(claims);
    if (sorted_claims) {
      
      // sorted_claims.forEach(claim => {
        for (let i = 0; i < sorted_claims.length; i++) {
          const claim = sorted_claims[i];
        // check if respective claim type exist or not
        claim['claim_data']["claim_status"] = status_obj[claim['claim_data']['external_status']]
        if (
          claims_container[
            claims_categories[
              status_obj[claim['claim_data']['external_status']]
            ]
          ]
        ) {
          // console.log(claims_container[
          //   claims_categories[
          //     status_obj[claim['claim_data']['external_status']]
          //   ]
          // ])
          claims_container[
            claims_categories[
              status_obj[claim['claim_data']['external_status']]
            ]
          ].push(claim);
        }
         else {
          claims_container['Approved'].push(claim);
        }
      }
      // );
    }
    setClaimsList(claims_container);
  };

  if (error) {
    return (
      <div className="serverDown_header">
         <div className="top_header_container">
          <div className="top_header_container_1">
          </div>
          <img
            src={hdfc}
            alt=""
            className='serverDownImg'
            style={{ cursor: 'pointer' }}
            onClick={() => {
              navigate('/policyDetails');
            }}
          />
      </div>
      <div className="server-down">
        <img src={ServerDown} alt="" />
      </div>
      </div>
    );
  }

  if  (emptyData) {
    return (
      <div className="serverDown_header">
           <div className="top_header_container">
          <div className="top_header_container_1">
          </div>
          <img
            src={hdfc}
            alt=""
            className='serverDownImg'
            style={{ cursor: 'pointer' }}
            onClick={() => {
              navigate('/policyDetails');
            }}
          />
        </div>
      <div className="data_not_found">
        <div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={empty} alt="nf" />
          </div>
          <p className='notfoundtextD'>Please click a policy to check claim details.</p>
          <p className='notfoundtextM'>Sorry we have not found any claim data for you.</p>
        </div>
      </div>
      </div>
    );
  }

 if (existedClaimsData) {
    return (
      <div className="claims_list_main_container">
        <div>
          <ClaimStatus set_and_shift_to_status={setClaimData} available_claims_data={existedClaimsData}/>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        height: '90vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-b',
        alignItems: 'center',
      }}
    >
      <div className='claimLoader'>
      {loading_status && loading_status ? <Loader1/> : <></>}
      {/* <Loader1/> */}
      </div>
      {/* <span style={{ fontWeight: '500'}}>{loading_status}</span> */}
      {/* <PolicyDetails/> */}
    </div>
    
  );
};

export default Claims;
