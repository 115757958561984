import React from 'react';
import './Loader.css';
import ctrlnLogo from '../../Assets/ctrlnLogo.png';
// import HDFC3Ologo from '../../Assets/HDFC3.Ologo.png';




const Loader = ({balls_color}) => {

  return (
    <div className="loader">
      <div className="sub_loader">
        <div style={{background:balls_color}} className="circle_loader"></div>
        <div style={{background:balls_color}} className="circle_loader"></div>
        <div style={{background:balls_color}} className="circle_loader"></div>
        <div style={{background:balls_color}} className="circle_loader"></div>
      </div>
    </div>
  );
};

export default Loader;
