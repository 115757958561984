import React, { useState, useContext } from 'react';
import bankdetail from '../../Assets/bank-details.svg';
// import BankDetailsImg from '../../Assets/BankDetailsImg.svg';
import BankDetailsImg from '../../Assets/bankDetailsPng.png';

import uparrow from '../../Assets/uparrow.svg';
import downarrow from '../../Assets/downarrow.svg';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import './BankDetails.css';
import { ClaimContext } from '../../context/ClaimContext';
import Modal from '@mui/material/Modal';
import {returnSingleName} from '../../utility'


const BankDetailsDesktop = () => {
  const [show, setShow] = useState(false);
  const context = useContext(ClaimContext);

  return (
    <>
      <div id={`${context.data.required_documents ? "panel1a-header1":"panel1a-header"}`}>
        <div className="claim-details-sub" onClick={() => setShow(!show)}>
          <img src={BankDetailsImg} alt="" />
          <p className="claim-details-text">
            <span className="heading">Bank Details</span> <br />
            Check your transaction Details
          </p>
        </div>
      </div>
    </>
  );
};

export default BankDetailsDesktop;

export const ShowBankDetails = () => {
  const context = useContext(ClaimContext);
  let {bank_data} = context

  const returnName = () =>{
    if(!bank_data){
      return "N/A"
    }else{
      return returnSingleName(context.bank_data.payee_name)
    }
  }

  return (
    <div className="bankdetailsdesktop">
      
      <div className="bankdetailsdesktopview">
        <div>
          <div className="person-detail">
            <p className="bank-call">Proposer name</p>
            <p className="bank-caller">: {returnName()}</p>
          </div>
          <div className="person-detail">
            <p className="bank-call">Bank name</p>
            <p className="bank-caller">: {bank_data?context.bank_data.bank_name :"N/A"}</p>

            {/* <p className="bank-caller">: {context.bank_data.bank_name ||"N/A"}</p> */}
          </div>
        </div>
        <div>
          <div className="person-detail">
            <p className="bank-call">Account number</p>
            <p className="bank-caller">: {bank_data?context.bank_data.account_no :"N/A"}</p>
            {/* <p className="bank-caller">: {`${context.bank_data.account_no ||"N/A"}`}</p> */}
          </div>

          <div className="person-detail">
            <p className="bank-call">Account type</p>
            <p className="bank-caller">: {bank_data?context.bank_data.account_type :"N/A"}</p>

            <p className="bank-caller">
              {/* : {`${context.bank_data.account_type ||"N/A"}`} */}
            </p>
          </div>
        </div>
        <div>
          <div className="person-detail">
            <p className="bank-call">IFSC Code</p>
            <p className="bank-caller">: {bank_data?context.bank_data.ifsc_code :"N/A"}</p>

            {/* <p className="bank-caller">: {context.bank_data.ifsc_code ||"N/A"}</p> */}
          </div>
          <div className="person-detail">
            <p className="bank-call">Payment Details</p>
            <p className="bank-caller">: {bank_data?context.bank_data.neft_code :"N/A"}</p>

            {/* <p className="bank-caller">: {context.bank_data.neft_code ||"N/A"}</p> */}
          </div>
        </div>

        {/* <div>
          <p className="call">Patient name</p>
          <p className="call">Hospital name</p>
          <p className="call">Relationship</p>
          <p className="call">Ailment type</p>
          <p className="call">Date of admission</p>
          <p className="call">Date of discharge</p>
          <p className="call">Polict type</p>
          <p className="call">Policy number</p>
        </div>
        <div>
          <p className="caller">Arjun Jain</p>
          <p className="caller">Namcare Hospital Guwahati</p>
          <p className="caller">Self</p>
          <p className="caller">Leg Injury</p>
          <p className="caller">12/12/2021</p>
          <p className="caller">14/12/2021</p>
          <p className="caller">Optima Secure Individual</p>
          <p className="caller">23e456787654</p>
        </div> */}
      </div>
    </div>
  );
};
