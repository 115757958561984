import React, { useState } from 'react';
import './ClaimStatusCard.css';
import { updates_accordingto_intimation, status_obj } from '../../../utility';
import ReactGA from 'react-ga';


const status = {
  "reason_for_rejection":true,
  "required_documents":true
}



const ClaimStatusCard = ({ current_claim, setClaimsData , setCashlessPopUpStat}) => {


  const handleClaimData  = () => {
    let mobile = localStorage.getItem('mobile')
    // PREAUTH
    // if(current_claim.claim_data['claim_type'] === 'Pre- Authorization Approved'|| current_claim.claim_data['claim_type'] === 'Cashless'|| current_claim.claim_data['claim_type'] === 'CASHLESS'){
    //   setCashlessPopUpStat(true)
    // }else{

      setClaimsData(current_claim)
    // }
  }



  if (current_claim && current_claim.claim_data) {
    return (
      <div
        // onClick={() => setClaimsData(current_claim)}
        onClick={handleClaimData}

        className="claimStatus_section4"
      >
        <div className="claimStatus_details">
          <div className="claimStatus_details_claims">
            <p>Claim ID</p>
            <p>{current_claim.claim_data['claim_id']}</p>
          </div>
          <div className="claimStatus_details_claimsId">
            <div className="claimStatus_doc_ver_div">
              <p>{status_obj[current_claim.claim_data['external_status']]==='Verification'?'Document Verification': status_obj[current_claim.claim_data['external_status']]==='Initiated'?'Claim Intimated':status_obj[current_claim.claim_data['external_status']] === "Disbursed" ? "Claim Approved & Disbursed" : status_obj[current_claim.claim_data['external_status']] === "Approved" ? "Claim Approved" :status_obj[current_claim.claim_data['external_status']]==='Rejected'?'Claim Rejected': status_obj[current_claim.claim_data['external_status']]}</p>
            </div>
            <p>Intimate Date: {current_claim.claim_data['claim_intimation']}</p>
          </div>
        </div>
        <div className="claimStatus_reimbursement">
          <p>
            {current_claim.claim_data['claim_type'] === 'SUPPLEMENTRY'
              ? 'Pre-Post Claims'
              : current_claim.claim_data['claim_type']}
          </p>
        </div>
        <div className="claimStatus_more">
          <p className={current_claim.claim_data['required_documents'] ? 'required_documents_text':''}>
            {current_claim.claim_data['reason_for_rejection'] 
              ? current_claim.claim_data['reason_for_rejection'].substr(0, 60)
              : current_claim.claim_data['required_documents'] ? 'Action required by customer'
               : 
               updates_accordingto_intimation(
                  // current_claim.claim_data['claim_intimation']
                  current_claim.claim_data['claim_status']
                )}
            {/* <span className="claimStatus_more_span">{` `}More</span> */}
          </p>
        </div>
        
      </div>
    );
  } else {
    return <></>;
  }
};

export default ClaimStatusCard;
//current_claim.claim_data['claim_intimation']


