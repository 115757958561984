import React, { useContext } from 'react';
import './CardYellow.css';
import { ClaimContext } from '../../context/ClaimContext';
import Skeleton from 'react-loading-skeleton';
import optimaSecureImg from '../../Assets/optima_secure.svg';

const CardYellow = () => {
  const context = useContext(ClaimContext);

  if (context.data) {
    return (
      <>
        <section className="card-yellow-mainNares">
          {/* <section className="card-yellow-main"> */}
          <div className="card-content">
            {/* <div className="yellow-card-box">
              <p className="yellow-card-amount">
                &#8377; {context.data.total_claimamount}/-
              </p>
              <p className="yellow-less-bold">Claim Amount Filed</p>
            </div> */}
          </div>
          {/* <div className="yellow-card-status">
            <p className="yellow-semi-bold">
              <span className="yellow-less-bold">Claim Id </span>
              <span className="yellow-semi-bold"> {context.data.claim_id}</span>
            </p>
            <p className="yellow-semi-bold">
              <span className="yellow-less-bold">Status </span>
              <span className="yellow-semi-bold"> Document Verification</span>
            </p>
            <p className="yellow-less-bold link">
              {context.documents ? (
                `${context.documents.length} doc uploaded , ${context.data.required_documents ? "More doc required" : ""}`
              ) : (
                <div></div>
              )}
            </p>
          </div> */}

          <div className="optima_secure_div">
            <div className="optima_secure_imgtext">
              <div className="optima_secure_img">
                <img src={optimaSecureImg} alt="" />
              </div>
              <div className="optima_secure_txt">
                <p>{context.data.product_name.substr(0, 50)}</p>
                <p>{context.data.policy_number}</p>
              </div>
            </div>
            <div className="optima_secure_txt_f">
              <p>Claim Id</p>
              <p>Status</p>
            </div>
            <div className="optima_secure_txt_s">
              <p>{context.data.claim_id}</p>
              <p>
                <span className="doc_ver_dot"></span>{' '}
                {context.data.claim_status === 'Verification'
                  ? 'Document Verification'
                  : context.data.claim_status}
              </p>
            </div>
            {/* <hr/> 
            <div className="optima_secure_txt_l">
        <p>Claim Amount Filed</p>
        <p>₹ {context.data.total_claimamount}/-</p>
      </div> */}
          </div>
        </section>
      </>
    );
  } else {
    return (
      <>
        <section className="card-main-skeleton">
          <Skeleton height="8.5rem" circle={false} count={1} />
        </section>
      </>
    );
  }
};

export default CardYellow;
