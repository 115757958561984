import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import cloudupload from '../../Assets/Cloud-Upload.svg';
import './DocumentUpload.css';
import { env } from '../../config/env';
import { ClaimContext } from '../../context/ClaimContext';
import uploadImg from '../../Assets/Icon/Upload.svg';
import pdfImg from '../../Assets/Icon/PDF.svg';
import insertPhotoImg from '../../Assets/Insertphoto.svg';

import Cross from '../../Assets/Cross.png';
import downloadImg from '../../Assets/Icon/Download.svg';
import ReactGA from 'react-ga';


const DocumentUpload = ({ setAlert, clearAlert, update_progress }) => {
  const context = useContext(ClaimContext);

  const [ivrFile, setIvrFile] = useState([]);
  const [ivrFileError, setIvrFileError] = useState('');
  const navigate = useNavigate();

  const [documentTypeSelected, setDocumentTypeSelected] = useState('');

  // const [ivrFileEditable, setIvrFileEditable] = useState('');
  const [ivrFileStatus, setivrFileStatus] = useState(false);
  const handleInput = e => {
    setDocumentTypeSelected(e.target.value);
    document.getElementById('ivr-file').click();
  };

  const handlelogout = () =>{
    window.sessionStorage.clear();
    navigate('/');
  }
  const handleIVRFile = event => {
    // ''''''''''''''''''''
    // setIvrFile([...event.target.files]);
    // setIvrFile([]);
    // const fileAttribute = event.target.files;
    // const file_size = event.target.files[0].size;
    // const file_name = event.target.files[0].name;
    // const file_type = event.target.files[0].type;

    // +================

    if (event.target.files[0] && event.target.files[0].size <= 800000) {
      // if(event.target.files[0].size> 8){
      //   return
      // }
      event.target.files[0].document_type = documentTypeSelected;

      let file_obj = {
        file: event.target.files[0],
      };
      ivrFile.push(event.target.files[0]);
      // setDocumentTypeSelected('');
      // if (ivrFile.length === 0) {
      //   for (let file of event.target.files) {
      //     console.log(ivrFile);
      //   }
      // }
      // =================
      setivrFileStatus(true);
      // For File Name
      // fi.map(file => {
      //   arrName.push(file.name);
      // });

      // fi.forEach(file => {
      //   arrName.push(file.name);
      // });

      // For File Size
      // fi.map(file => {
      //   arrFileSize.push(file.size);
      // });

      // fi.forEach(file => {
      //   arrFileSize.push(file.size);
      // });

      // let fileSum = sum(arrFileSize);

      // if (arrName.length <= 10 && fileSum <= 20000070) {
      //   setivrFileName(arrName.length === 1 ? '' : arrName);
      // } else {
      //   setivrFileName('');
      //   alert('Upload only 10 files less than 20MB ');
      // }

      // setIvrFile(fileAttribute.length > 1 ? '' : file_name);
    } else {
      setIvrFileError('please select document of 8mb or lesser size');
    }
  };

  const cancleBtn = ind => {
    const newIVRFile = ivrFile.filter((n, i) => i !== ind);
    setIvrFile(newIVRFile);
  };

  const IVReport = async () => {
    setAlert({
      status: true,
      msg: 'Uploading please wait...',
      warning_status: 'progress',
    });
    const formdata = new FormData();
    for (let i = 0; i < ivrFile.length; i++) {
      formdata.append('file', ivrFile[i]);
    }
    // console.log(formdata);
    try {
      const url = `${env.SERVER_URL}/document_upload_process/document_upload/${context.data.claim_id}/${context.data.external_status}`;
      const xhr = new XMLHttpRequest();
      const result_msg = await new Promise(resolve => {
        try {
          xhr.upload.addEventListener('progress', event => {
            if (event.lengthComputable) {
              update_progress(`${(event.loaded / event.total) * 100 - 1}`);
            }
          });

          xhr.addEventListener('load', e => {
            const response = JSON.parse(xhr.response);
            if (xhr.status === 200) {
              resolve(response);
            }
          });
          let token = window.sessionStorage.getItem('_ssid');
          xhr.open('POST', url, true);
          // xhr.setRequestHeader("Content-Type", "application/json")
          xhr.setRequestHeader('token', token);
          xhr.send(formdata);
          let mobile = localStorage.getItem('mobile');
          ReactGA.event({
            category: 'Document_Upload',
            action: 'Document_Upload',
            label:'Document_Upload'
          });
        } catch (err) {
          console.log('err: ', err);
          resolve({ err });
        }
      });
      if (result_msg.status && result_msg.data && !result_msg.update_error) {
        let msg;
        if (result_msg.data.failed === 0) {
          msg = `${
            result_msg.data.success > 1
              ? `${result_msg.data.success} files are uploaded successfully.`
              : `${result_msg.data.success} file uploaded successfully.`
          }`;
        } else if (result_msg.data.failed > 0) {
          msg = `${
            result_msg.data.success > 1
              ? `${result_msg.data.success} files are uploaded successfully.`
              : `${result_msg.data.success} file uploaded successfully`
          }. ${
            result_msg.data.failed > 1
              ? `${result_msg.data.failed} files are rejected}`
              : `${result_msg.data.failed} file is rejected`
          }`;
        }
        setIvrFile([]);
        setivrFileStatus(false);
        update_progress(result_msg.data.failed === 0 ? '100' : 0);
        setAlert({
          status: true,
          msg,
          warning_status: result_msg.data.failed === 0 ? 'success' : 'error',
        });
        // context.fetchDocumentsData();
        // update status
        if (context.data.external_status === 'New Request') {
          context.updateIntimationStatus(
            context.data.current_index,
            'Claims Doc Received'
          );
        }

        setTimeout(() => {
          clearAlert();
        }, 5000);
      }

      setIvrFile([]);
      setDocumentTypeSelected('');
    } catch (err) {
      console.log('err: ', err);
      setIvrFile([]);
      setivrFileStatus(false);
      setAlert({ status: true, msg: 'worng' });
      setTimeout(() => {
        clearAlert();
      }, 5000);
    }
    setIvrFile([]);
    setDocumentTypeSelected('');
  };

  return (
    <>
      <section className="document-upload-main">
        {/* <div className="document-upload-sub">
          <img src={redtick} alt="" />
          <p>Investigation Report</p>
          <img src={infotooltip} alt="" />
        </div> */}

        <div className="fileinputs" onClick={handlelogout}>
          <p style={{ fontSize: '12px', fontWeight: '400' }}>
            {context.data.required_documents || ''}
          </p>
          <div className="file-up">
            {/* <div className='uploadBtn'>
              <span style={{paddingRight:10}}>Upload</span><img src={uploadImg}/>
            </div> */}
            {documentTypeSelected !== null &&
            documentTypeSelected !== undefined ? (
              <div className="upload_section">
                {/*  */}
                <select
                  name="id_proof"
                  value={documentTypeSelected}
                  onChange={handleInput}
                >
                  <option value="" id="uploadPendingDocDefault" default>
                    Upload Pending Document
                  </option>
                  <option value="KYC_Form">KYC Form</option>
                  <option value="Bills">Bills</option>
                  <option value="Consultation_Papers">
                    Consultation Papers
                  </option>
                  <option value="Investigation_Reports">
                    Investigation Reports
                  </option>
                  <option value="X-Ray_Plates_And_Film">
                    X-Ray Plates And Film
                  </option>
                  <option value="Letter">Letter</option>
                  <option value="ID_Proof">ID Proof</option>
                  <option value="Discharge_Card">Discharge Card</option>
                  <option value="Receipt">Receipt</option>
                  <option value="Query_Reply">Query Reply</option>
                  <option value="Others">Others</option>
                </select>
              </div>
            ) : (
              <>{/* <img src={cloudupload} alt="" /> Select doc... */}</>
            )}
          </div>
          <input
            type="file"
            // accept=".bmp,.tiff,.txt,.jpeg,application/pdf, .png"
            accept="application/pdf, .png, .jpeg, .jpg"
            id="ivr-file"
            multiple
            // value={ivrFile}
            style={{ display: 'none' }}
            onChange={e => handleIVRFile(e)}
          />
          {/* {ivrFile.length !== 0 ? (
            <button onClick={IVReport} className="upload-button">
              Upload
            </button>
          ) : (
            <button
              onClick={() => null}
              style={{ background: 'lightgrey' }}
              className="upload-button"
            >
              Upload
            </button>
          )} */}
        </div>
        <div>
          {ivrFile.length >= 1 ? (
            <div style={{ paddingTop: 10 }}>
              <h4 style={{ padding: '10px 10px -8px 5px' }}>
                Selected Documents
              </h4>
              {ivrFile.map((file, index) => {
                return (
                  <div className="documentVisibleSection" key={index}>
                    <div className="documentVisibleSectionLs">
                      <img
                        src={
                          file.type === 'application/pdf'
                            ? pdfImg
                            : insertPhotoImg
                        }
                        alt=""
                        className="DocumentUploadPartImg"
                      />

                      <div className="details_copntainer_documents">
                        <span className="document_type">
                          {file.name.substr(0, 18)}
                        </span>
                        <br />
                        <span className="document_date">01/07/2022</span>
                      </div>
                    </div>

                    <div className="documentVisibleSectionRs">
                      <div
                        onClick={() => cancleBtn(index)}
                        className="DocumentUploadPartUploadCanc"
                      >
                        <img src={Cross} alt="" />
                      </div>
                      {ivrFile.length === 1 ? (
                        <div
                          onClick={() => IVReport()}
                          className="DocumentUploadPartUploadCanc"
                        >
                          <img src={uploadImg} alt="" />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <></>
          )}
          <div className="document_validation">
            {ivrFileError
              ? ivrFileError
              : 'Only pdf, jpeg, jpg and png allowed(Max-Size: 8Mb)'}
          </div>

          {ivrFile.length > 1 ? (
            <div className="uploadCancBtn">
              {/* <button onClick={cancleBtn} className="upload-button">
            X
          </button> */}
              <button onClick={() => IVReport()} className="upload-button">
                Upload
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </section>

      {/* 
      
      // const handleXray = event => {
  //   const fileAttribute = event.target.files;
  //   const file_size = event.target.files[0].size;
  //   const file_name = event.target.files[0].name;
  //   const file_type = event.target.files[0].type;

  //   const fi = Array.from(event.target.files);

  //   // For File Name
  //   fi.map(file => {
  //     arrName.push(file.name);
  //   });

  //   // For File Size
  //   fi.map(file => {
  //     arrFileSize.push(file.size);
  //   });

  //   let fileSum = sum(arrFileSize);

  //   if (arrName.length <= 10 && fileSum <= 20000070) {
  //     setxrayFileName(arrName.length === 1 ? '' : arrName);
  //   } else {
  //     setxrayFileName('');
  //     alert('Upload only 10 files less than 20MB ');
  //   }

  //   setXRay(fileAttribute.length > 1 ? '' : file_name);
  // };

  // const XRay = () => {
  //   const formdata = new FormData();
  //   formdata.append('xrayfile', xRay);
  //   const url = 'http://localhost:3001/xray_upload';

  //   fetch(url, {
  //     method: 'POST',
  //     body: formdata,
  //   })
  //     .then(res => res.json())
  //     .then(ress => {
  //       // console.log(ress);
  //       // setXRay(null);
  //     })
  //     .catch(Error);
  // };

      <section className="additional-document">
        <p className="additional-document-bold">
          Additional Document Requested
        </p>
        <p className="additional-document-text">X-Ray Reports</p>
        <div className="fileinputs">
          <div
            className="file-up"
            onClick={() => document.getElementById('xray-file').click()}
          >
            {xRay === '' ? (
              <>
                <img src={cloudupload} alt="" /> Select doc...
              </>
            ) : (
              xRay
            )}
          </div>
          <input
            type="file"
            accept=".bmp,.tiff,.txt,.jpeg,application/pdf"
            multiple
            id="xray-file"
            style={{ display: 'none' }}
            onChange={e => handleXray(e)}
          />

          <button onClick={XRay} className="upload-button">
            Upload
          </button>
        </div>
        <div>
          {xrayFileName &&
            xrayFileName.map((file, i) => {
              return <div key={i}>{file}</div>;
            })}
        </div>
      </section> */}
    </>
  );
};

export default DocumentUpload;
