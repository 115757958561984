import { Routes, Route, Navigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { checkAuthorization, storeDataInIndexDb } from './utility';
import Otp from './Components/Otp/Otp';
import Login from './Components/Login/Login';
import Claims from './Components/Claims/Claims';
import MainApp from './Components/MainApp/MainApp';
import MainApp1 from './Components/MainApp/MainApp1';

import Loader from './Components/Loader/Loader';
import ClaimStatus from './Components/ClaimStatus/ClaimStatus';
import PolicyDetails from './Components/PolicyDetails/PolicyDetails';
import DocumentUploadPart from './Components/DocumentUploadPart/DocumentUploadPart';
import {
  ProtectedRoute,
  UnProtectedRoute,
} from './ProtectedRoutes/ProtectedRoutes';
import { env } from './config/env';
import Loader1 from './Components/Loader/Loader1';
import Ratings from './Components/Ratings/Ratings';
import ReactGA from 'react-ga';
import Test from './Components/Test';
let trackingId = 'G-YE6Z5ML5C3';
ReactGA.initialize(trackingId);

function App1() {
  const [mobile, setMobile] = useState(localStorage.getItem('mobile'));
  const [claimsData, setClaims] = useState(null);
  const [claimsListData, setClaimsDataList] = useState(JSON.parse(window.sessionStorage.getItem('claims_data')));
  const setMobileNo = mobile => {
    setMobile(mobile);
  };

  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, [])

  const setClaimsData = (claims, callback) => {
    setClaims(claims);
    window.sessionStorage.setItem('claim', JSON.stringify(claims));
    callback();
  };

  const updateIntimationStatus = index => {
    if (index) {
      claimsData['external_status'] = 'Claims Doc Received';
      claimsData['claim_status'] = 'Verification';
      // update in main list
      claimsListData[index]['external_status'] = 'Claims Doc Received';
      claimsListData[index]['claim_status'] = 'Verification';
    }
  };

  const setClaimsList = claimsList => {
    // console.log("setclaim in app", claimsList)
    setClaimsDataList(claimsList);
    window.sessionStorage.setItem('claims_data', JSON.stringify(claimsList));
  };

  const handleSubmit = event => {
    const data = { mobileno: mobile };
    const url = `${env.SERVER_URL}/auth/get_otp/${mobile}`;

    fetch(url, {
      method: 'POST',
      headers: new Headers({ 'content-type': 'application/json' }),
      // mode: 'no-cors',
      body: JSON.stringify(data),
    })
      .then(ress => {})
      .catch(err => {
        return null;
      });
  };

  useEffect(() => {}, []);

  return (
    <>
      <Routes>
        {/* <Route path="/test" element={<Loader1 />} /> */}
        {/* <Route path="/test" element={<Test/>} /> */}

        <Route
          path="/claimstatus/:encclaimNumber/:encpolicyNumber"
          element={
            <MainApp1
              updateIntimationStatus={updateIntimationStatus}
              current_claim={claimsData}
              setClaims={setClaimsData}
              mobile={mobile}
              setClaimsList={setClaimsList}
              existedClaimsData={claimsListData}
            />
          }
        />
        <Route
          path="/claimstatus/:encclaimNumber/:encpolicyNumber/here"
          element={
            <MainApp1
              updateIntimationStatus={updateIntimationStatus}
              current_claim={claimsData}
              setClaims={setClaimsData}
              mobile={mobile}
              setClaimsList={setClaimsList}
              existedClaimsData={claimsListData}
            />
          }
        />
        <Route
          path="/claimstatus/:encclaimNumber/:encpolicyNumber/self-help"
          element={
            <MainApp1
              updateIntimationStatus={updateIntimationStatus}
              current_claim={claimsData}
              setClaims={setClaimsData}
              mobile={mobile}
              setClaimsList={setClaimsList}
              existedClaimsData={claimsListData}
            />
          }
        />
        <Route
          path="/"
          element={
            <UnProtectedRoute>
              <Login setMobileNo={setMobileNo} />
            </UnProtectedRoute>
          }
        />
        <Route
          path="/validation"
          element={
            <UnProtectedRoute>
              <Otp submit={handleSubmit} mobile={mobile} />
            </UnProtectedRoute>
          }
        />
        <Route
          path="/status"
          element={
            <ProtectedRoute>
              <MainApp
                updateIntimationStatus={updateIntimationStatus}
                current_claim={claimsData}
                setClaims={setClaimsData}
                mobile={mobile}
              />
            </ProtectedRoute>
          }
        />

        <Route
          path="/:usersPolicyNumber/claimList/:id"
          element={
            <ProtectedRoute>
              <PolicyDetails
                setClaimsList={setClaimsList}
                setClaims={setClaimsData}
                existedClaimsData={claimsListData}
                mobile={mobile}
              />
            </ProtectedRoute>
          }
        />

        <Route
          path="/policyDetails"
          element={
            <ProtectedRoute>
              <PolicyDetails
                setClaimsList={setClaimsList}
                mobile={mobile}
                setClaims={setClaimsData}
                existedClaimsData={claimsListData}
              />
            </ProtectedRoute>
          }
        />

        <Route
          path="*"
          element={
            <h2
              style={{ textAlign: 'center', marginTop: '30%', color: 'orange' }}
            >
              <br /> Something Went Wrong
            </h2>
          }
        />
      </Routes>

      {/* <Routes>
        <Route
          path="/"
          element={
            checkAuthorization() ? (
              <Navigate to="/claimList" />
            ) : (
              <Login setMobileNo={setMobileNo} />
            )
          }
        />
        <Route
          path="/validation"
          element={
            checkAuthorization() ? (
              <Navigate to="/claimList" />
            ) : (
              <Otp submit={handleSubmit} mobile={mobile} />
            )
          }
        />
        <Route
          path="/status"
          element={
            checkAuthorization() ? (
              <MainApp
                updateIntimationStatus={updateIntimationStatus}
                current_claim={claimsData}
                mobile={mobile}
              />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        {/* <Route
          path="/documentUpload"
          element={<DocumentStatus back={leftArrow} />}
        /> */}
      {/* <Route */}
      {/* path="/claimList"
          element={
            // checkAuthorization() ? (
            <Claims
              setClaimsList={setClaimsList}
              setClaims={setClaimsData}
              existedClaimsData={claimsListData}
              mobile={mobile} */}
      {/* />
            // ) : (
            //   <Navigate to="/" />
            // ) */}

      {/* />
      </Routes> */}
    </>
  );
}

export default App1;
