import React, { useEffect, useState } from 'react';
import { useNavigate, Routes, Route, Link, useParams } from 'react-router-dom';
import Header from '../Header/Header'
 

// import days from 'dayjs'


import './PolicyDetails.css';
import goodByImg from '../../Assets/goodbye.png';
import EmptyStateImg from '../../Assets/EmptyState.svg';
import hdfc from '../../Assets/hdfclogo.png';
import { env } from '../../config/env';
import Loader from '../Loader/Loader1';
import Claims from '../Claims/Claims';


import pdfImg from '../../Assets/Icon/PDF.svg';
import viewImg from '../../Assets/Icon/On.svg';
import downloadImg from '../../Assets/Icon/Download.svg';
import infoImg from '../../Assets/Icon/Info.svg';
import optimaSecureImg from '../../Assets/optima_secure.svg';
import cxArrowImg from '../../Assets/Cx_arrow.png';
import logoutImg from '../../Assets/logout.svg';
import ReactGA from 'react-ga';
import policyResp from './policyResp.json'

// import optimaSecureImg from '../../Assets/healthIconSvg.svg';

const PolicyDetails = ({ mobile, setClaimsList,setClaims,existedClaimsData }) => {
  
  const {usersPolicyNumber} = useParams();


  const [deviceSize, changeDeviceSize] = useState(window.screen.width);
  const [loading_status, setLoadingStatus] = useState(false);
  const [selectedpol, setSelectedpol] = useState(usersPolicyNumber||window.sessionStorage.getItem('selPol'))

  const [policy_no, setPolicy_no] = useState(
    JSON.parse(window.sessionStorage.getItem('policy')) || []
  );

  const navigate = useNavigate();

  console.log("selected policy", selectedpol)


  // console.log(deviceSize)
  useEffect(() => {
    changeDeviceSize(window.screen.width)
  }, [])
  
  // const [policy_no, setPolicy_no] = useState(policyArrjson);

  // console.log('policyDetailsbymobile', policy_no);

  // const [loading, setloading] = useState(false);

  const mobile_no = localStorage.getItem('mobile') || mobile;

  // filter policy based on claim
  // function policyHavingClaim(claimArrWithPolicy, policyArr){
  //   let polArrObj = [];
  //   for (let i = 0; i < claimArrWithPolicy.length; i++) {
  //     for (let j = 0; j < policyArr.length; j++) {
  //       if (policyArr[j].PolicyNo == claimArrWithPolicy[i]) {
  //         polArrObj.push(policyArr[j]);
  //       }
  //     }
  //   }

  //   window.sessionStorage.setItem('policy', JSON.stringify(polArrObj));
  //   setPolicy_no(polArrObj)
  //   setLoadingStatus(false);
  // }

  const policyDetailsbyMobile = async () => {
    let existed_policy = await JSON.parse(
      window.sessionStorage.getItem('policy')
    );

    if (!existed_policy) {
      setLoadingStatus("true");
      try {
        const requestOptions = {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            token: window.sessionStorage.getItem('_ssid'),
          },
          body: JSON.stringify({ user_ssid: mobile_no }),
        };
        const res = await fetch(
          `${env.SERVER_URL}/get_claims/get_policy_nos`,
          requestOptions
        );
        const response = await res.json();
          if(response?.status === "Not authenticated")
          {
            window.sessionStorage.clear();
            navigate('/');
          }
        if(response?.ExtraData?.LinkedPolicy !==undefined){
          setPolicy_no(response?.ExtraData?.LinkedPolicy);
        window.sessionStorage.setItem(
          'policy',
          JSON.stringify(response.ExtraData.LinkedPolicy)
        );
        }
        // setPolicy_no(policyResp);
        // window.sessionStorage.setItem(
        //   'policy',
        //   JSON.stringify(policyResp)
        // );
        setLoadingStatus(false);
      } catch (error) {
        setLoadingStatus(false);
      }
    }
  };

  const handleRouteToClaim = (policyNo,index) => {
    console.log('handleRouteToClaim', policyNo, index);
    // return
    ReactGA.event({
      category: 'Policy_details_fetched',
      action: 'Policy_details_fetched',
      label: 'Policy_details_fetched'
    });
    setSelectedpol(policyNo)
    window.sessionStorage.setItem('selPol', policyNo)
      navigate(`/${policyNo}/claimList/In Progress Claims`);
      if(deviceSize>1024){
        window.location.reload();
      }


  };
  const ActiveN = date => {
    // console.log("nnnnnnnnnnn", now.format());
    let newD = date.split('/');
    let exDate = new Date(`${newD[1]}/${newD[0]}/${newD[2]} 23:59:59`).getTime();
    let cDate = new Date().getTime();
    // console.log('cDate', cDate.format())
    // console.log('exDate', exDate.format())
    if (exDate >= cDate) {
      return true;
    } else {
      return false;
    }
  };

  const dateFormate = (date) =>{
    let newD = date.split('/').join("-");
    return newD
  }
  const handleLogoutPolicy = () => {
    window.sessionStorage.clear()
    navigate('/')
  }

  useEffect(() => {
    setClaimsList(null);
    policyDetailsbyMobile();
  }, []);



   




  return (
    <>
    {
      loading_status == false&&
    
    <div>
      <Header  fineText ="Hope You are doing fine" pcText="Your Policy Numbers" />
      <div className='policyWeb'>

      {/* <div className="policy_details_header"> */}
        {/* <div className="top_header_container">
          <div className="top_header_container_1">
            <div className="claimStatus_top">
              <p>Hello</p>
              <img src={goodByImg} alt="goodByImg" />
            </div>
            <p style={{ color: '#7C7C7C', fontSize: 14 }}>
              Hope You are doing fine
            </p>
            
          </div>
          <img src={hdfc} alt="" />
          
        </div>
        <div className='policy_logout' onClick={handleLogoutPolicy}>
        <img  src={logoutImg} alt=""/>
        </div>
        
        <span
          style={{
            fontSize: '16px',
            fontWeight: 400,
            paddingBottom: '10px',
            display: 'inline-block',
          }}
        >
          Your Policy Numbers
        </span> */}
        
      {/* </div> */}

      {policy_no.length > 0 ? (

      <div className="PolicyNumbersDiv">
        {policy_no.length > 0 &&
          policy_no.map((policyNo, i) => {
            // console.log(policyNo);
            let a = 0;
            if (policyNo.ProductType == 'HEALTH') {
              a++
              return (
                <div
                  className={`PolicyNumbersDetails ${policyNo.PolicyNo==selectedpol?'selectedPolicy':''}`}
                  key={i+1}
                  onClick={()=>handleRouteToClaim(policyNo.PolicyNo, i)}
                  // to={`/${policyNo.PolicyNo}/claimList/In Progress Claims`}
                >
                  <div className="PolicyNumbersDetails_div" >
                    <img
                      src={optimaSecureImg}
                      alt=""
                      className="PolicyNumbersDetails_image"
                    />
                  </div>
                  <div className="PolicyNumbersDetails_text">
                    <p className="policy_name">{policyNo.ProductName}</p>
                    {/* <p>{policyNo.PolicyNo}</p> */}
                    <div className="PolicyNumbersDetails_text_p">
                      <p>{policyNo.PolicyNo} </p>
                      {/* <p>Exp: {policyNo.EndDate}</p> */}
                    </div>
                    <div className="PolicyNumbersDetails_text_p_l" >
                      <p>
                        <span className="label">Start Date</span> <br />
                        <span className="value"> {dateFormate(policyNo.StartDate)} </span>
                      </p>
                      <p className="PolicyNumbersDetails_text_p_l_expiryDate">
                        <span className="label">End Date</span> <br />
                        <span className="value"> {dateFormate(policyNo.EndDate)} </span>
                      </p>
                      {/* <div className="PolicyNumbersDetails_text_p_l">
                        <img src={cxArrowImg} alt="" />
                      </div> */}
                    </div>
                  </div>
                  <div
                    className={`policyStatus_doc_ver_div ${
                      ActiveN(policyNo.EndDate) ? 'bgGreen' : 'bgOrange'
                    }`}
                  >
                    <span
                      className={`policyStatus_doc_ver_dot ${
                        ActiveN(policyNo.EndDate) ? 'bgGreenDot' : 'bgOrangeDot'
                      }`}
                    ></span>{' '}
                    {`${ActiveN(policyNo.EndDate) ? 'Active' : 'Inactive'}`}
                  </div>
                  {/* <div className="policyStatus_doc_ver_div"></div> */}
                </div>
              );
            } else {
               <>No Record Found <p className='Logout_policy' onClick={handleLogoutPolicy}>Please Try With Another Number</p></>;
             }
          })}
      </div>):(<div className="policyStatusNotFound"><p >No Record Found</p><br/><p className='Logout_policy' onClick={handleLogoutPolicy}>Please Try With Another Number</p></div>)}
   <div className='policyClaimWebView'>
   {
      deviceSize>1024 && (<Claims setClaimsList={setClaimsList} setClaims={setClaims} existedClaimsData={existedClaimsData}/>)
    }
   </div>
    </div>
    </div>}
    
    {loading_status && loading_status ? (
          <Loader/>
      ) : (
        <></>
      )}

    </>
    
  );
};

export default PolicyDetails;
