import React, { useEffect, useState } from 'react';
import './Login.css';
import { useNavigate } from 'react-router-dom';
import { env } from '../../config/env';
import ReactGA from 'react-ga';
import { AlertComponent } from '../Alert/Alert';
import axios from 'axios';


const Login = ({ setMobileNo }) => {
  const navigate = useNavigate();

  const [mobile, setMobile] = useState(localStorage.getItem('mobile') || '');
  const [isPending, setIsPendeing] = useState(false);
  const [tcFocus, setTcFocus] = useState(false);
  const [alertStatus, setAlertStatus] = useState({
    status: false,
    msg: null,
    warning_status: '',
  });

  const clearAlert = () => {
    setAlertStatus({ status: false, msg: null, warning_status: '' });
  };

  const setAlert = alert => {
    setAlertStatus(alert);
  };


  const handleOnChange = async event => {
    const val = parseInt(event.target.value) || '';

    if (typeof val === 'number') {
      setMobile(val);
      await localStorage.setItem('mobile', val);
    } else {
      setMobile('');
    }
  };

  // Form Submit

  const handleSubmit = async (event) => {
    event.preventDefault();
    ReactGA.event({
      category: 'Login_User',
      action: 'Login_User',
      label:'Login_User'
    })
    if(!navigator.onLine){
      return
    }
    if (mobile.toString().length !== 10) {
      return;
    } else {
      
      // const data = { mobileno: mobile };
      setIsPendeing(true);
      try {
        const ress = await axios.get(`${env.SERVER_URL}/auth/get_otp/${mobile}`)

          setIsPendeing(false);
          setMobileNo(mobile);
          if(ress?.data?.data === "Otp limit exceeded"){
            setAlert({
              status: true,
              msg: "Otp limit exceeded, please try again after 15 minutes",
              warning_status: 'success',
            });
            setTimeout(() => {
              // navigate('/validation');
              clearAlert();
            }, 3000);
            // console.log(ress);
            return
          }
          // navigate('/validation');
      // const url = `${env.SERVER_URL}/auth/get_otp1/${mobile}`;
      // fetch(url, {
      //   method: 'GET',
      //   headers: new Headers({ 'content-type': 'application/json' }),
      //   // mode: 'no-cors',
      //   // body: JSON.stringify(data),
      // })
      //   // .then(res => res.body.json())
      //   .then(async ress => {
      //     console.log("ress",ress)
      //     setIsPendeing(false);
      //     setMobileNo(mobile);
      //     if(ress?.data === "Otp limit exceeded"){
      //       setAlert({
      //         status: true,
      //         msg: ress?.data,
      //         warning_status: 'success',
      //       });
      //       setTimeout(() => {
      //         // navigate('/validation');
      //         clearAlert();
      //       }, 3000);
      //       // console.log(ress);
      //       return
      //     }
      //     navigate('/validation');
      //   });

      setMobileNo(mobile);
      navigate('/validation');
    } catch (error) {
      console.log('error',error)
    }
    }
  };

  useEffect(() => {
    window.sessionStorage.removeItem('claims_data');
  }, []);

  return (
    <div style={{ width: '90%', margin: '0 auto' }} className="loginPage">
      <span className="login-text">Verify Account</span>
      <div className="login-text-desc">
        Add your mobile number. We'll send you a verification code.
      </div>
      <div className="text-field">
        <input
          name="mobileno"
          className="login-input"
          type="text"
          placeholder="Enter Mobile Number"
          required
          pattern="\d*"
          autoComplete="off"
          maxLength={10}
          value={mobile}
          onChange={handleOnChange}
          onFocus={()=>{setTcFocus(true)}}
          // autoFocus
        />
      </div>

      {!isPending && (
        <button className="submit-button" onClick={handleSubmit}>
          Send OTP
        </button>
      )}
      {isPending && (
        <button className="submit-button" disabled>
          Sending...
        </button>
      )}
      <p className={tcFocus?"termsAndConditionF":"termsAndCondition"}>Terms and Conditions Applied. #1.51 Crore+ Active Customers as on July - 2022 © HDFC ERGO General Insurance Company Limited. CIN: U66030MH2007PLC177117. IRDAI Registration Number : 146 (Registration type: General Insurance Company).  Registered & Corporate Office: 1st Floor, HDFC House, 165-166 Backbay Reclamation, H. T. Parekh Marg, Churchgate, Mumbai – 400 020. Customer Happiness Center / Policy Issuing Address: D-301, 3rd Floor, Eastern Business District (Magnet Mall), LBS Marg, Bhandup (West), Mumbai - 400 078. For Claim/Policy related queries call us at +91 22 6234 6234/+91 120 6234 6234 or Visit Help Section on www.hdfcergo.com for policy copy/tax certificate/make changes/register & track claim.  For more details on the risk factors, terms and conditions, please read the sales brochure/ prospectus before concluding the sale. Trade Logo displayed above belongs to HDFC Ltd and ERGO International AG and used by the Company under license.</p>

      {alertStatus.status ? (
          <div>
            <AlertComponent alert_status={alertStatus} />
          </div>
        ) : (
          <></>
        )}

    </div>
  );
};

export default Login;
